import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import cx from 'classnames';

import { ReactComponent as BotIcon } from 'Assets/chat-bot.svg';
import LoadingCss from "Components/LoadingCss/LoadingCss";
import style from './FeedMessageLine.module.scss';
import { formatDateWithTime } from "Helpers/date";

export type FeedMessageLineDataT = JSX.Element|JSX.Element[]|string|string[]|any[];

export type MainPropsFeedT = {
    data: FeedMessageLineDataT,
    className?: string,
    showAvatar?: boolean,
    float?: 'left'|'right'|undefined,
    marked?: boolean,
    transparent?: boolean,
    hideAvatar?: boolean,
    spaceBottom?: boolean,
    dateString?: string,
    dataOverText?: { text: string, placeInLastCloud?: boolean },
    disabled?: boolean,
    loading?: boolean,
    center?: boolean
}

const FeedMessageLine: React.FC<MainPropsFeedT> = ({ data, className, showAvatar = true, float = 'left', marked, transparent, hideAvatar, spaceBottom, dateString, dataOverText, disabled, loading, center }) => {

    const getDate = (date: string) => {
        return formatDateWithTime(new Date(date));
    }

    const dataContent = useMemo(() => (d: any, idx: number) => {
        const baseText = d[0];
        const additionalText = d?.[1];
        return Array.isArray(d) ?
            <>
                {(idx === 0 && dateString) && <div className={style.date}>{getDate(dateString)}</div>}
                {typeof baseText === 'string' ? <div className={cx({ [style.textTop]: additionalText })}>{parse(baseText)}</div> : baseText}
                {(additionalText) &&
                    <div className={style.contentUnder}>
                        {typeof additionalText === 'string' ? parse(additionalText) : additionalText}
                    </div>
                }
            </>:
            <>
                {(idx === 0 && dateString) && <div className={style.date}>{getDate(dateString)}</div>}
                {typeof d === 'string' ? parse(d) : d}
            </>
    }, [dateString]);

    return (
        <>
            <div
                className={cx(style.root, className, { 
                    [style.left]: float === 'left',
                    [style.right]: float === 'right',
                    [style.marked]: marked,
                    [style.transparent]: transparent,
                    [style.spaceBottom]: spaceBottom,
                    [style.disabled]: disabled,
                    [style.center]: center
                })}
            >
                {loading && <div className={style.loading}><LoadingCss width={100} version={1} borderWidth={10} /></div>}
                {((!float || float === 'left') && !hideAvatar) &&
                    <div className={style.lefLayer}>
                        {showAvatar &&
                            <div className={style.avatar}>
                                <BotIcon />
                            </div>
                        }
                    </div>
                }
                <div className={style.content}>
                    {(dataOverText && !dataOverText.placeInLastCloud) &&
                        <div className={style.dataOverText}>
                            <span>{dataOverText.text}</span>
                        </div>
                    }
                    {Array.isArray(data) ?
                        <>
                            {data.map((d: any, idx: number) => {
                                const isMarked = !!dataOverText && (!dataOverText.placeInLastCloud ? idx === 0 : idx === data.length - 1);
                                const showDataOverCloud = idx > 0 && isMarked;
                                return (
                                    <React.Fragment key={`fedd-${idx}`}>
                                        {showDataOverCloud &&
                                            <div className={style.dataOverText}>
                                                <span>{dataOverText.text}</span>
                                            </div>
                                        }
                                        <div className={cx(style.infoCloud, { [style.marked]: isMarked })} key={`k-${idx}`}>
                                            {/* {(!!iconType && !arrayFirst(d) && idx === 0) && getIconType()} */}
                                            {dataContent(d, idx)}
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </>:
                        <div className={cx(style.infoCloud, { [style.marked]: !!dataOverText })}>
                            {dataContent(data, 0)}
                        </div>
                    }
                </div>
                {(float === 'right' && !hideAvatar) &&
                    <div className={style.lefLayer}>
                        {showAvatar &&
                            <div className={style.avatar}>
                                <BotIcon />
                            </div>
                        }
                    </div>
                }
            </div>
        </>
    );
}
export default FeedMessageLine;