import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import { ResultApiTypeT, runApi, sendDataFiles } from "../../../DataStructure/services";
import { Button, Checkbox, FeedMessageLine } from "../../../DataStructure/Components";
import { ReportUploadBox } from "../../../DataStructure/Components";
import { Loading } from "../../../DataStructure/Components";
import { IImageT } from "../../../DataStructure/interfaces";
import { runNotify } from "../../../DataStructure/helpers";
import style from './RepairBudgetDataEntry.module.scss';

type MainPropsT = {
    t: (data: string, params?: any) => string,
    disabled: boolean,
    claimId: string
}

const RepairBudgetDataEntry: React.FC<MainPropsT> = ({ disabled, t, claimId }) => {

    const [photo, setPhoto] = useState<IImageT>(null);
    const [sending, setSending] = useState(false);

    const actionChangeImage = useCallback((photo: IImageT) => {
        setPhoto(photo);
    }, [])

    const removeDamageImage = useCallback((photo: IImageT) => {
        setPhoto(null);
    }, []);

    const actionSendWithPhoto = useCallback(() => {
        if (disabled) return;
        setSending(true);
        sendDataFiles(`claim/${claimId}/assessment-result/spare-parts/upload-document`, [{ file: photo }], (r: ResultApiTypeT) => {
            setSending(false);
            if (!r.result){
                runNotify({ message: r.error.message || t('registration.errorRegister'), type: 'error' });
            } else {
                setPhoto(null);
            }
        });
    }, [claimId, t, photo, disabled]);

    const actionSendResignation = useCallback(() => {
        if (disabled) return;
        setSending(true);

        runApi(`claim/${claimId}/assessment-result/spare-parts/send-refusal`, {}, (r: ResultApiTypeT) => {
            setSending(false);
            if (!r.result){
                runNotify({ message: r.error.message || t('registration.errorRegister'), type: 'error' });
            } else {
                setPhoto(null);
            }
        });
    }, [claimId, t, disabled]);

    return (
        <FeedMessageLine
            data={(
                <div className={style.content}>
                    {t('chatMessages.sparePartsHeader')}
                    <hr />
                    <FormInputData
                        actionSendResignation={actionSendResignation}
                        actionSendWithPhoto={actionSendWithPhoto}
                        actionChangeImage={actionChangeImage}
                        removeDamageImage={removeDamageImage}
                        sending={sending}
                        photo={photo}
                        t={t}
                        disabled={disabled}
                    />
                </div>
                )}
            disabled={disabled}
        />
    );
}
export default RepairBudgetDataEntry;

type FormInputDataT = {
    photo: IImageT,
    removeDamageImage: (photo: IImageT) => void,
    actionChangeImage: (photo: IImageT) => void,
    t: (text: string, data?: any) => string,
    actionSendWithPhoto: () => void,
    actionSendResignation: () => void,
    sending: boolean,
    disabled: boolean
}

const FormInputData: React.FC<FormInputDataT> = ({ actionChangeImage, photo, removeDamageImage, t, actionSendWithPhoto, actionSendResignation, sending, disabled }) => {

    const [checked, setChecked] = useState(false);

    return (
        <div className={style.root}>

            <div className={style.upload}>
                <div className={style.uploadTitle}>{t('chatMessages.repairBudgetFilesHeader')}</div>
                {sending ?
                    <div className={style.loading}>
                        <Loading />
                    </div>:
                    <ReportUploadBox
                        value={!checked && photo}
                        removable={true}
                        onRemove={() => removeDamageImage(photo)}
                        onChange={(value) => actionChangeImage(value)}
                        name={'photo-Input'}
                        disabled={sending || checked || disabled}
                        hideName
                        className={cx(style.inputBox, { [style.enabled]: !(disabled || checked),[style.disabled]: disabled || checked })}
                    />
                }
            </div>
            <div className={style.mainButtons}>
                <div className={style.orTitle}>...{t('common.or')}</div>
                <div className={cx(style.orLayer, { [style.orLaterSelected]: checked, [style.disabled]: disabled })} onClick={!disabled ? () => setChecked(v => !v) : undefined}>
                    <div className={style.chooseType}>
                        <Checkbox
                            checked={checked}
                            name={t('chatMessages.sparePartsButtonCancel')}
                            onChange={(v, event) => {
                                event.preventDefault();
                                setChecked(!v);
                            }}
                            disabled={sending || disabled}
                        />
                        <span>- {t('chatMessages.sparePartsButtonCancel')}</span>
                    </div>
                    <div className={style.noReportComment}>{t('chatMessages.repairBudgetNoReport')}</div>
                </div>
                <div className={style.buttons}>
                    {checked ?
                        <Button onClick={actionSendResignation} disabled={sending || disabled}>{t('chatMessages.repairBudgetButtonRefuse')}</Button>:
                        <Button disabled={!!!photo || sending || disabled} onClick={actionSendWithPhoto}>{t('chatMessages.sparePartsButtonConfirm')}</Button>
                    }
                </div>
            </div>

        </div>
    )
}