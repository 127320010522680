import { LocalStateT, PhotoT } from "./ReportProblem";

export const Store = (state: LocalStateT, action: any): LocalStateT => {

    switch(action.type){

        case 'SET_IMAGES': 
        
            let images = state.images;
            const findImage = state.images.find(im => im.type === action.dataType);
            if (!findImage){
                images = [...images, ...[{ type: action.dataType, images: action.data }]];
            } else {
                images = state.images.map((im: PhotoT) => {
                    if (im.type === action.dataType){
                        im.images = action.data;
                    }
                    return im;
                });
            }

            return {
                ...state,
                images
        }

        case 'SET_INPUTS': 

            let inputs = state.inputs;
            const findInput = state.inputs.find(inp => inp.type === action.dataType);
            if (!findInput){
                inputs = [...inputs, ...[{ type:action.dataType, value: action.data }]];
            } else {
                inputs = state.inputs.map(inp => {
                    if (inp.type === action.dataType){
                        inp.value = action.data;
                    }
                    return inp;
                });
            }

            return {
                ...state,
                inputs
            }

        case 'SET_VALID': return {
            ...state,
            valid: action.data
        }

        case 'SET_SENDING': return {
            ...state,
            sending: action.data
        }

        default: return state;
    }

}

export default Store;