import React, { useMemo } from 'react';

import{ FeedMessageLine } from "../../../DataStructure/Components";
import { CLAIM_STATUSES } from "../../../DataStructure/interfaces";
import { getFeedTypeData } from "../../../DataStructure/helpers";

type MainPropsT = {
    t: (data: string, params?: any) => string,
    location?: LocationT,
    pickupDate: string,
    email: string,
}

// [SOLUTION_PICKUP_PROCESS]
const PickupDropOff: React.FC<MainPropsT> = ({ t, location, pickupDate, email }) => {

    const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.SOLUTION_DROP_OFF, t), [t]);
    const address = useMemo(() => location?.formattedAddress, [location]);

    return (
        <>
            {address &&
                <FeedMessageLine
                    data={(
                        <>
                            <i>{t('chatMessages.pickupDataPaxEntryLabel')}</i><br /><br />
                            {t('chatMessages.resolutionAddress')}:<br />
                            <strong>{address}</strong><br /><br />
                            {t('chatMessages.pickupDate')}: <br /><strong>{pickupDate}</strong>
                            {email &&
                                <>
                                    <br /><br />
                                    {t('chatMessages.pickupEmail')}:<br /><strong>{email}</strong>
                                </>
                            }
                        </>
                    )}
                    spaceBottom
                    float={'right'}
                />
            }
            <FeedMessageLine
                data={dataShow}
                spaceBottom
            />
        </>
    );
}
export default React.memo(PickupDropOff);