import { useDeviceType as useDeviceTypeMain } from "Helpers/responsiveContainers";
import useReportTagTranslationMain from "Hooks/useOriginalTags";
import useFormattedPriceMain from "Hooks/useFormattedPrice";
import useOrderMain from "Hooks/useOrder";
import useTranslateMain from "Hooks/useTranslate";

export const useFormattedPrice = useFormattedPriceMain;
export const useDeviceType = useDeviceTypeMain;
export const useReportTagTranslation = useReportTagTranslationMain;
export const useOrder = useOrderMain;
export const useTranslate = useTranslateMain;