import { AirlineT } from "PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm";
import { CurrencyT } from "Store/modules/luggageStore/shop";
import { codes } from 'country-calling-code';

import { CLAIM_STATUSES } from "Config/statuses";
import { ClaimT } from "api/modules/claim";

export const removeDuplicatedNamesAirlines = (airlines: { text: string, value: string }[]): { text: string, value: string }[] => {
    let tempAirlines: { text: string, value: string }[] = [];
    airlines.forEach(al => {
        if (!tempAirlines.some(a => a.text === al.text)) {
            tempAirlines.push(al);
        }
    });
    return tempAirlines;
}

export const searchAirlineById = (airlines: AirlineT[], id: string) => {
    const foundAirline = airlines.find(a => a.Id === id)
    return foundAirline ? `[${foundAirline.Name}] ${foundAirline?.Airline__c}` : ''
}

export const setDefaultCurrency = (currencies: CurrencyT[], codeCountry: string): string => {
    const countryPln = ['pl'];
    const plnCurrency = currencies.find(c => c.code.toLowerCase() === 'pln');
    if (plnCurrency && countryPln.includes(codeCountry.toLowerCase())) {
        return plnCurrency.code;
    }
    const countryCzech = ['cz', 'cs-cz'];
    const czechCurrency = currencies.find(c => c.code.toLowerCase() === 'czk');
    if (czechCurrency && countryCzech.includes(codeCountry.toLowerCase())) {
        return czechCurrency.code;
    }
    const euroCurrency = currencies.find(c => c.code.toLowerCase() === 'eur');
    if (euroCurrency) {
        return euroCurrency.code;
    }
    if (currencies && Array.isArray(currencies) && currencies.length > 0) {
        return currencies[0].code;
    }
    return 'EUR';
}

export const getUrlMissingDoc = (url: string, countryCode: string): string => {

    if (url.length <= 10) {
        return '';
    }
    const mainSplit = url.split(';');
    if (mainSplit.length === 1 && url.charAt(2) !== ':') {
        return url;
    }
    const tArray: { url: string, code: string }[] = [];
    mainSplit.forEach(el => {
        const cCode = el.substring(0, 2);
        const cUrl = el.substring(3);
        if (cCode === countryCode) {
            return cUrl;
        }
        tArray.push({ url: cUrl, code: cCode });
    });
    const findSameCode = tArray.find(t => t.code === 'EN');
    if (findSameCode) {
        return findSameCode.url;
    }
    if (tArray.length > 0 && tArray[0].url.length > 10) {
        return tArray[0].url;
    }
    return '';
}

export const prepareUrl = (claim: ClaimT, isMobile: boolean) => {
    const urlBack = `${window.location.protocol}//${window.location.host}/${claim?.id}`;
    const urlSuccess = `${window.location.protocol}//${window.location.host}/${isMobile ? 'claim/' : ''}${claim?.id}/successSpareParts`;

    if (claim?.verdict && claim?.verdict.resolution?.moneyCompensationUrl) {
        return claim.verdict.resolution.moneyCompensationUrl + `&urlBack=${urlBack}&urlSuccess=${urlSuccess}`;
    }
    return urlBack;
};

export const sizeAdapter = (size: string, countryCode: string, language: string): string => {

    if (countryCode.toUpperCase() === 'BR') {
        switch (size.toUpperCase()) {
            case 'XS': return 'PP';
            case 'S': return 'P';
            case 'M': return 'M';
            case 'L': return 'G';
            case 'XL': return 'EG';
            case 'XXL': case '2XL': return '2EG';
            case 'XXXL': case '3XL': return '3EG';
        }
    } else if (language.toUpperCase() === 'DE') {
        switch (size.toUpperCase()) {
            case 'XS': return 'XS = < 50cm';
            case 'S': return 'S = 50-55cm';
            case 'M': return 'M = 60-69cm';
            case 'L': return 'L = 70-79cm';
            case 'XL': return 'XL = 80-89cm';
            case 'XXL': case '2XL': return 'XXL = > 90cm';
        }
    }
    return size;
}

export const photoMainKey = (item: ClaimT) => {
    const photo = item?.case?.files?.photos?.find(_photo => _photo.type === 'damage1');
    if (photo && photo?.name) return photo?.thumbnail?.key;
    return item?.case?.files?.photos?.[0]?.thumbnail?.key || item?.case?.files?.photos?.[0]?.original?.key || null;
};

export const removePrefixFromPhone = (phone: string, prefix: string | undefined | null): string => {
    if (!prefix) return phone;
    if (phone.trim().indexOf('+' + prefix) === 0) {
        return phone.trim().substring(('+' + prefix).length);
    }
    if (phone.trim().indexOf("+") === 0) {
        const code = codes.find(c => phone.trim().indexOf("+" + c.countryCodes[0]) === 0);
        if (code) {
            return phone.trim().substring(('+' + code.countryCodes[0]).length);
        }
        return phone.trim().substring(1);
    }
    return phone;
}

export const isPathArchive = (): boolean => window.location.pathname.indexOf('/archive/') >= 0;

export const getFeedTypeData = (status: CLAIM_STATUSES, t: (data: string, params?: any) => string, dataTranslate?: any, dataTranslateMain?: any, hideExtend = false): string|any[] => {

    const keyTranslate = 'caseStatusesBasic.' + status + '_MAIN';
    const translate = t(keyTranslate, dataTranslateMain || dataTranslate);
    if (translate !== keyTranslate){
        const keyTranslateExtend = 'caseStatusesBasic.' + status + '_EXTEND';
        const translateExtend = t(keyTranslateExtend, dataTranslate);
        if (translateExtend !== keyTranslateExtend && !hideExtend){
            return [
                [
                    translate,
                    translateExtend,
                    true
                ]
            ]
        }
        return translate;
    }
    return t(`caseStatuses.${status}`);

}