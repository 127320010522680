import { useSelector } from "react-redux";
import React from 'react';

import { AppStateT, UserStateT } from "../../../DataStructure/interfaces";
import { TAG_TO_NAME } from "../ReportProblemDataEntry/ReportProblem";
import Resolution from "./Resolution/Resolution";


type MainPropsT = {
    t: (data: string, params?: any) => string,
    resolution: ReportT['resolution']
}

const ReportProblemCompleted: React.FC<MainPropsT> = ({ t, resolution }) => {

    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);

    return (
        <>
            {resolution &&
                <Resolution resolution={resolution} t={t} tagToNameData={TAG_TO_NAME} user={user} />
            }
        </>
    );
}
export default ReportProblemCompleted;