import { Input as InputMain, LoadableContent as LoadableContentMain, Button as ButtonMain } from "Components";
import PhoneDirectMain from "PageComponents/CreateClaim/CreateClaim/CreateForm/Steps/Contact/PhoneDirect/PhoneDirect";
import FeedMessageLineMain from "Components/FeedMessageLine/FeedMessageLine";
import { ReportUploadBox as ReportUploadBoxMain } from "PageComponents/Chat";
import ExternalnfoDataMain from "Components/ExternalnfoData/ExternalnfoData";
import LocationSearchMain from "Components/LocationSearch/LocationSearch";
import DropDownSearchMain from "Components/DropDownSearch/DropDownSearch";
import ContactBranchMain from "Components/ContactBranch/ContactBranch";
import ImageDecryptMain from "Components/ImageDecrypt/ImageDecrypt";
import MessageModalMain from "Components/MessageModal/MessageModal";
import LoadingCssMain from "Components/LoadingCss/LoadingCss";
import CheckboxMain from "Components/Checkbox/Checkbox";
import LoadingMain from "Components/Loading/Loading";
import LoaderMain from "Components/Loader/Loader";
import { Modal as  ModalMain } from "rsuite";
import IcoMain from 'Components/Ico/Ico';


export const ImageDecrypt = ImageDecryptMain;
export const ReportUploadBox = ReportUploadBoxMain;
export const Button = ButtonMain;
export const Checkbox = CheckboxMain;
export const FeedMessageLine = FeedMessageLineMain;
export const Loading = LoadingMain;
export const LoadingCss = LoadingCssMain;
export const Modal = ModalMain;
export const ContactBranch = ContactBranchMain;
export const Ico = IcoMain;
export const Input = InputMain;
export const LoadableContent = LoadableContentMain;
export const ExternalnfoData = ExternalnfoDataMain;
export const DropDownSearch = DropDownSearchMain;
export const PhoneDirect = PhoneDirectMain;
export const Loader = LoaderMain;
export const LocationSearch = LocationSearchMain;
export const MessageModal = MessageModalMain;
