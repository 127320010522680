import { 
    formatSecondsToTimeString as formatSecondsToTimeStringMain,
    getSecondsDifference as getSecondsDifferenceMain,
    getClosestWorkingDay as getClosestWorkingDayMain,
    calculateDateDifference as calculateDateDifferenceMain,
    getAgeName as getAgeNameMain,
    getMiddleOfTheDay as getMiddleOfTheDayMain,
    getNextBusinessDate as getNextBusinessDateMain,
    formatDateSaleforce as formatDateSaleforceMain,
    formatDatePickerDate as formatDatePickerDateMain,
} from "Helpers/date";
import { getFeedTypeData as getFeedTypeDataMain, getUrlMissingDoc as getUrlMissingDocMain, sizeAdapter as sizeAdapterMain } from "Helpers/claim";
import { makeSlug as makeSlugMain, formatInput as formatInputMain } from "Helpers/strings";
import { countriesCodes as countriesCodesMain } from "Helpers/languageCode";
import { defaultLanguage as defaultLanguageMain } from "Helpers/i18";
import { runNotify as runNotifyMain } from "Helpers/notifications";
import { validField as validFieldMain } from "Helpers/validForm";
import { AddressT as AddressMainT } from "Helpers/googleApi";

export const getFeedTypeData = getFeedTypeDataMain;

export const formatSecondsToTimeString = formatSecondsToTimeStringMain;
export const getSecondsDifference = getSecondsDifferenceMain;
export const getClosestWorkingDay = getClosestWorkingDayMain;
export const calculateDateDifference = calculateDateDifferenceMain;
export const getAgeName = getAgeNameMain;
export const runNotify = runNotifyMain;
export const makeSlug = makeSlugMain;
export const getUrlMissingDoc = getUrlMissingDocMain;
export const sizeAdapter = sizeAdapterMain;
export const countriesCodes = countriesCodesMain;
export const defaultLanguage = defaultLanguageMain;
export const validField = validFieldMain;
export const getNextBusinessDate = getNextBusinessDateMain;
export const getMiddleOfTheDay = getMiddleOfTheDayMain;
export const formatDateSaleforce = formatDateSaleforceMain;
export const formatDatePickerDate = formatDatePickerDateMain;
export const formatInput = formatInputMain;
export type AddressT = AddressMainT;