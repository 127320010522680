import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import cx from 'classnames';

import { getUrlShopToken } from "../../../DataStructure/services";
import { LoadingCss } from "../../../DataStructure/Components";
import { SvgCart } from "../../../DataStructure/SvgData";
import { CatalogsE } from "Store/Enums/urlsCatalogs";
import style from './SelectStore.module.scss';

type MainPropsT = {
    t: (data: string, params?: any) => string,
    disabled?: boolean,
    catalogs: string[],
    claimId: string
}

const SelectStore: React.FC<MainPropsT> = ({ t, disabled, catalogs, claimId }) => {

    const [loadingCatalogData, setLoadingCatalogData] = useState<string|null>(null);
    const dispatch = useDispatch();

    const getShopName = useMemo(() => (name: string): string => {
        switch (name) {
            case CatalogsE.ENTER_AIR: return 'ENTER AIR CATALOG';
            case CatalogsE.LCC: return 'LOW COST CATALOG';
            case CatalogsE.QCC: return 'QUICK CUSTOMER CHOICE';
            case CatalogsE.QSC: return 'QUICK SELECTION CATALOG';
            default: return 'MAGENTO CATALOG';
        }
    }, []);

    const actionGoShop = useCallback((catalog: CatalogsE) => {
        if (!!loadingCatalogData || disabled) return;
        setLoadingCatalogData(catalog);
        getUrlShopToken(catalog, claimId, dispatch, () => {
            setLoadingCatalogData(null);
        });
    }, [dispatch, claimId, loadingCatalogData, disabled]);

    return (
        <div className={style.root}>
            {(catalogs && Array.isArray(catalogs)) &&
                <div className={cx(style.shopLayer, { [style.shopLayerDisabled]: disabled })}>
                    <div className={style.shopHeader}>{catalogs.length === 1 ? t('chatMessages.shopsContentOne') : t('chatMessages.shopsContentMore')}</div>
                    {catalogs.length > 0 &&
                        <>
                            {catalogs.map((catalog: CatalogsE) => {
                                return (
                                    <div onClick={() => actionGoShop(catalog)} className={style.catalogItem} key={`catalog-${catalog}`}>
                                        <div className={style.ico}>
                                            {loadingCatalogData === catalog ?
                                                <LoadingCss width={60} color={'white'} />:
                                                <SvgCart />
                                            }
                                        </div>
                                        <div className={style.textBody}>
                                            <div className={style.catalog}><span className={style.shopName}>{getShopName(catalog)}</span> - <span className={cx(style.catalog, style.catalogContent)}>
                                                {catalog === CatalogsE.MAGENTO ? t('chatMessages.shopContentMagento') : t('chatMessages.shopContentOther')}
                                            </span></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
            }
        </div>
    );
}
export default SelectStore;