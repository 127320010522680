import { TypeOptions } from "react-toastify";

import { ConfigShopT } from "./luggageStore/shop";
import { isPathArchive } from "Helpers/claim";
import { ClaimT } from "api/modules/claim";

export const SHOW_HELP_CENTER = 'SHOW_HELP_CENTER';
export const SHOW_PERSONAL_INFORMATION = 'SHOW_PERSONAL_INFORMATION';
export const SHOW_MODAL_LANGUAGES = 'SHOW_MODAL_LANGUAGES';
export const SHOW_MODAL_ARTICLE = 'SHOW_MODAL_ARTICLE';
export const SHOW_MODAL_TOOLTIP = 'SHOW_MODAL_TOOLTIP';
export const CHANGE_DOCUMENTS_CLAIMS = 'CHANGE_DOCUMENTS_CLAIMS';
export const CHANGE_AIRPORTS = 'CHANGE_AIRPORTS';
export const SET_STORE_CONFIG = 'SET_STORE_CONFIG';
export const SET_PAGE_CONFIG = 'SET_PAGE_CONFIG';
export const SET_NOTIFY = 'SET_NOTIFY';
export const BLOCK_SERVER_ERROR = 'BLOCK_SERVER_ERROR';
export const RELOAD_CLAIMS = 'RELOAD_CLAIMS';
export const SET_MESSAGE_CASE = 'SET_MESSAGE_CASE';
export const SET_ACCEPT_COOKIES = 'SET_ACCEPT_COOKIES';
export const SET_SHOW_CONTACT = 'SET_SHOW_CONTACT';
export const SET_CLAIMS_ARCHIVED = 'SET_CLAIMS_ARCHIVED';
export const OPEN_MODAL_REDIRECT_STORE = 'OPEN_MODAL_REDIRECT_STORE';
export const OPEN_MODAL_STORE_SUCCESS = 'OPEN_MODAL_STORE_SUCCESS';
export const SET_WAITING_SHOP_RESULT = 'SET_WAITING_SHOP_RESULT';

export type SetMessageCaseT = {
    type: typeof SET_MESSAGE_CASE;
    claim: ClaimT|null
};

export type ChangeHelpCenterT = {
    type: typeof SHOW_HELP_CENTER;
    data: boolean
};

export type ChangePersonalINformationT = {
    type: typeof SHOW_PERSONAL_INFORMATION;
    data: boolean
};

export type BlockServerErrorT = {
    type: typeof BLOCK_SERVER_ERROR;
    data: BlockServerT
};

export type SetNotifyT = {
    type: typeof SET_NOTIFY;
    data: NotifyT
};

export type ChangeStoreConfigT = {
    type: typeof SET_STORE_CONFIG;
    data: StoreConfigT
};

export type SetPageConfigT = {
    type: typeof SET_PAGE_CONFIG;
    data: PageConfigT
};

export type ChangeModalLanguagesT = {
    type: typeof SHOW_MODAL_LANGUAGES;
    data: boolean
};

export type ReloadClaimsT = {
    type: typeof RELOAD_CLAIMS;
    value?: number
};

export type AirportT = {
    title: string,
    slug: string
}

export type dataModalArticleT = {
    title?: string | null,
    content?: string | JSX.Element | JSX.Element[] | null | undefined,
    pagesContentKey?: string | null | undefined,
    object?: JSX.Element,
    callback?: (data?: any) => void
}

export type AcceptCookiesT = {
    acceptCookies: boolean,
    showCookies: boolean
}

export type ChangeModalArticleT = {
  type: typeof SHOW_MODAL_ARTICLE;
  data: dataModalArticleT | null
};

export type ChangeModalTooltipT = {
  type: typeof SHOW_MODAL_TOOLTIP;
  data: dataModalArticleT | null
};

export type SetAcceptCookiesT = {
  type: typeof SET_ACCEPT_COOKIES;
  data: AcceptCookiesT
};

export type SetShowContactT = {
  type: typeof SET_SHOW_CONTACT;
  data: string|null
};

export type SetClaimsArchivedT = {
  type: typeof SET_CLAIMS_ARCHIVED;
  data: boolean
};

export type OpenModalRedirectStoreT = {
  type: typeof OPEN_MODAL_REDIRECT_STORE;
  data: boolean
};

export type OpenModalStoreSuccessT = {
  type: typeof OPEN_MODAL_STORE_SUCCESS;
  data: boolean
};

export type SetWaitingShopResultT = {
  type: typeof SET_WAITING_SHOP_RESULT;
  data: boolean
};

export interface StoreConfigBaseT extends ConfigShopT {
    claimId: string
}

export type StoreConfigT = StoreConfigBaseT | null;

export type NotifyT = {
    message: string,
    type?: TypeOptions,
}|null

export type BlockServerT = 'live'|'start'|null;

export type StateT = {
    showHelpCenter: boolean, 
    showPersonalInformation: boolean,
    showModalLanguages: boolean,
    showModalArticle: dataModalArticleT | null,
    showModalTolltip: dataModalArticleT | null,
    storeConfig: StoreConfigT,
    pageConfig: PageConfigT,
    notify: NotifyT,
    blockServerError: BlockServerT,
    reloadClaims: number,
    messageCase: ClaimT|null,
    showContact: string|null,
    acceptCookies: boolean,
    cookiesShow: boolean,
    claimsArchived: boolean,
    showModalRedirectShop: boolean,
    showModalStoreSuccess: boolean,
    waitingShopResult: boolean
};

export function setBlockServerError(data: BlockServerT): BlockServerErrorT {
    return {
      type: BLOCK_SERVER_ERROR,
      data,
    };
}

export function changeShowHelpCenter(data: boolean): ChangeHelpCenterT {
    return {
      type: SHOW_HELP_CENTER,
      data,
    };
}
export function changeShowPersonalInformation(data: boolean): ChangePersonalINformationT {
    return {
      type: SHOW_PERSONAL_INFORMATION,
      data,
    };
}

export function changeShowModalLanguages(data: boolean): ChangeModalLanguagesT {
    return {
      type: SHOW_MODAL_LANGUAGES,
      data,
    };
}

export function changeModalArticle(data: dataModalArticleT|null): ChangeModalArticleT {
    return {
      type: SHOW_MODAL_ARTICLE,
      data
    };
}

export function changeModalTooltip(data: dataModalArticleT|null): ChangeModalTooltipT {
    return {
      type: SHOW_MODAL_TOOLTIP,
      data
    };
}

export function changeStoreConfig(data: StoreConfigT): ChangeStoreConfigT {
    return {
      type: SET_STORE_CONFIG,
      data
    };
}

export function setPageConfig(data: PageConfigT): SetPageConfigT {
    return {
      type: SET_PAGE_CONFIG,
      data
    };
}

export function setNotify(data: NotifyT): SetNotifyT {
    return {
      type: SET_NOTIFY,
      data
    };
}

export function setReloadClaims(value?: number): ReloadClaimsT {
    return {
      type: RELOAD_CLAIMS,
      value
    };
}

export function setMessageClaim(claim: ClaimT|null): SetMessageCaseT {
    return {
      type: SET_MESSAGE_CASE,
      claim
    };
}

export function setCookiesAccept(data: AcceptCookiesT): SetAcceptCookiesT {
    return {
      type: SET_ACCEPT_COOKIES,
      data
    };
}

export function setShowContact(data: string|null): SetShowContactT {
    return {
      type: SET_SHOW_CONTACT,
      data
    };
}

export function setClaimArchived(data: boolean): SetClaimsArchivedT {
    return {
      type: SET_CLAIMS_ARCHIVED,
      data
    };
}

export function setOpenModalRedirectStore(data: boolean): OpenModalRedirectStoreT {
    return {
      type: OPEN_MODAL_REDIRECT_STORE,
      data
    };
}

export function setOpenModalStoreSuccess(data: boolean): OpenModalStoreSuccessT {
    return {
      type: OPEN_MODAL_STORE_SUCCESS,
      data
    };
}

export function setWaitingShopResult(data: boolean): SetWaitingShopResultT {
    return {
      type: SET_WAITING_SHOP_RESULT,
      data
    };
}

export type ContactBranchT = {
    fullName: string,
    phoneNumber: string,
    email: string,
    address: string,
    url: string
}

export type PageConfigT = {
    // fileUploadSize: number,
    UPLOAD_MAX_ALLOWED_FILES_SIZE: number,
    branchesContacts: {
        [data: string]: ContactBranchT
    },
    airlinesWithRabatVoucher: string[],
    branchesContactsByLocation: {
        [data: string]: ContactBranchT
    },
    noFractionCurrencies: string[],
    branchesCurrencies: {
        [data: string]: string[]
    },
    claim: {
        brands: string[],
        sizes: {
            [key: string]: string
        },
        catalogsUrls: {
            [key: string]: string
        },
        reportTagsMapReversed: {
            [key: string]: string
        }
    },
    pax: {
        salutation: {
            salutationEnum: {
                [key: string]: string
            },
            salutationNames: {
                [key: string]: string
            },
        },
        title: {
            titleByLocation: string[],
            titleEnum: {
                [key: string]: string
            },
            titleNames: {
                [key: string]: string
            },
        }
    },
    claimChat: {
        MAX_MESSAGE_LENGTH: number,
        CLAIM_CHAT_LOCK_INTERVAL_SEK: number,
        AVAILABLE_BRANCHES: string[],
        AVAILABLE_LANGUAGE_SKILLS: string[],
        AVAILABLE_STATUES: string[]
    },
    inputs: {
        airlineProcessingNumber: {
            maxLength: number,
            minLength: number,
            regexp: string
        },
        brand: {
            maxLength: number,
            minLength: number
        },
        city: {
            maxLength: number,
            minLength: number
        },
        email: {
            maxLength: number,
            minLength: number,
            regexp: string
        },
        measurements: {
            maxLength: number,
            minLength: number,
            regexp: string
        },
        number: {
            maxLength: number,
            minLength: number,
            regexp: string
        },
        phone: {
            maxLength: number,
            minLength: number,
            regexp: string
        },
        referenceNumber: {
            maxLength: number,
            minLength: number,
            regexp: string
        },
        rimowaSerialNumber: {
            maxLength: number,
            minLength: number,
            regexp: string
        },
        serialNumber: {
            maxLength: number,
            minLength: number,
            regexp: string
        },
        text: {
            maxLength: number,
            minLength: number
        },
        textarea: {
            maxLength: number,
            minLength: number
        },
        tumiSerialNumber: {
            maxLength: number,
            minLength: number,
            regexp: string
        }
    }
}

const initialState: StateT = {
    showHelpCenter: false,
    showPersonalInformation: false,
    showModalLanguages: false,
    showModalArticle: null,
    showModalTolltip: null,
    showContact: null,
    storeConfig: null,
    pageConfig: {
        UPLOAD_MAX_ALLOWED_FILES_SIZE: 5 * 1024 * 1024,
        airlinesWithRabatVoucher: [],
        branchesContacts: {},
        branchesContactsByLocation: {},
        noFractionCurrencies: [],
        branchesCurrencies: {},
        claim: {
            brands: [],
            sizes: {},
            catalogsUrls: {},
            reportTagsMapReversed: {}
        },
        pax: {
            salutation: { 
                salutationEnum: {},
                salutationNames: {}
            },
            title: {
                titleByLocation: [],
                titleEnum: {},
                titleNames: {}
            }
        },
        claimChat: {
            MAX_MESSAGE_LENGTH: 1000,
            CLAIM_CHAT_LOCK_INTERVAL_SEK: 60,
            AVAILABLE_BRANCHES: [],
            AVAILABLE_LANGUAGE_SKILLS: [],
            AVAILABLE_STATUES: []
        },
        inputs: {
            airlineProcessingNumber: {
                maxLength: 30,
                minLength: 6,
                regexp: "^(?!\\ )(?:[A-Z0-9]|[\\/\\ \\-|]){6,30}(?<!\\ )$"
            },
            brand: {
                maxLength: 50,
                minLength: 2
            },
            city: {
                maxLength: 40,
                minLength: 1
            },
            email: {
                maxLength: 100,
                minLength: 6,
                regexp: "^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@(([^<>()[\\]\\.,;:\\s@\\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\\"]{2,})$"
            },
            measurements: {
                maxLength: 50,
                minLength: 2,
                regexp: "^(?!\\s)[\\w\\d\\ \\*\\-]{2,50}$"
            },
            number: {
                maxLength: 32,
                minLength: 0,
                regexp: "^-?\\d+(\\.\\d+)?$"
            },
            phone: {
                maxLength: 20,
                minLength: 7,
                regexp: "^\\+?\\d[\\d\\s()-]{7,20}\\d$"
            },
            // phone: {
            //     maxLength: 19,
            //     minLength: 8,
            //     regexp: "^\\+?([0-9]{1,4})\\)?[-. ]?([0-9]{3,6})[-. ]?([0-9]{4,5})$"
            // },
            referenceNumber: {
                maxLength: 30,
                minLength: 6,
                regexp: "^(?!\\ )(?:[A-Z0-9]|[\\/\\ \\-|]){6,30}(?<!\\ )$"
            },
            rimowaSerialNumber: {
                maxLength: 17,
                minLength: 13,
                regexp: "^(?=\\d{13,17}$)\\d+$"
            },
            serialNumber: {
                maxLength: 40,
                minLength: 3,
                regexp: "^(?!\\ )(?:[a-zA-Z0-9]|[\\/\\ \\-|\\.,]){3,40}(?<!\\ )$"
            },
            text: {
                maxLength: 255,
                minLength: 0
            },
            textarea: {
                maxLength: 1000,
                minLength: 0
            },
            tumiSerialNumber: {
                maxLength: 7,
                minLength: 7,
                regexp: "^(?:[A-Z]|[a-z]|[0-9]){7}$"
            },
        }
    },
    notify: null,
    blockServerError: null,
    reloadClaims: 0,
    messageCase: null,
    acceptCookies: true,
    cookiesShow: false,
    claimsArchived: isPathArchive(),
    showModalRedirectShop: false,
    showModalStoreSuccess: false,
    waitingShopResult: false
};

type ActionTypeT = ChangeHelpCenterT | ChangePersonalINformationT | ChangeModalLanguagesT | ChangeModalArticleT | ChangeModalTooltipT | ChangeStoreConfigT | SetNotifyT | BlockServerErrorT | SetPageConfigT | ReloadClaimsT | SetMessageCaseT | SetAcceptCookiesT | SetShowContactT | SetClaimsArchivedT | OpenModalRedirectStoreT | OpenModalStoreSuccessT | SetWaitingShopResultT;

const pageReducer = (state: StateT = initialState, action: ActionTypeT): StateT => {

    switch (action.type){

        case SHOW_HELP_CENTER:

            return {
                ...state,
                showHelpCenter: action.data,
                showModalLanguages: false,
                showModalArticle: null,
                showModalTolltip: null
            }
            case SHOW_PERSONAL_INFORMATION:

            return {
                ...state,
                showPersonalInformation: action.data,
                showModalLanguages: false,
                showModalArticle: null,
                showModalTolltip: null
            }

        case SHOW_MODAL_LANGUAGES: 

            return {
                ...state,
                showModalLanguages: action.data,
                showHelpCenter: false,
                showModalArticle: null,
                showModalTolltip: null,
            }

        case SHOW_MODAL_ARTICLE:

            return {
                ...state,
                showModalArticle: action.data,
                showHelpCenter: false,
                showModalLanguages: false,
                showModalTolltip: null,
            }

        case SHOW_MODAL_TOOLTIP:

            return {
                ...state,
                showModalArticle: null,
                showHelpCenter: false,
                showModalLanguages: false,
                showModalTolltip: action.data,
            }

        case SET_STORE_CONFIG:

            return {
                ...state,
                storeConfig: action.data
            }

        case SET_NOTIFY:

            return {
                ...state,
                notify: action.data
            }

        case BLOCK_SERVER_ERROR:

            return {
                ...state,
                blockServerError: action.data
            }

        case SET_PAGE_CONFIG:

            return {
                ...state,
                pageConfig: action.data
            }

        case RELOAD_CLAIMS:

            return {
                ...state,
                reloadClaims: action?.value !== undefined ? action.value : (state.reloadClaims + 1)
            }

        case SET_MESSAGE_CASE: return {
            ...state,
            messageCase: action.claim
        }

        case SET_ACCEPT_COOKIES: return {
            ...state,
            acceptCookies: action.data.acceptCookies,
            cookiesShow: action.data.showCookies
        }

        case SET_SHOW_CONTACT: return {
            ...state,
            showContact: action.data
        }

        case SET_CLAIMS_ARCHIVED: return {
            ...state,
            claimsArchived: action.data
        }

        case OPEN_MODAL_REDIRECT_STORE: return {
            ...state,
            showModalRedirectShop: action.data
        }

        case OPEN_MODAL_STORE_SUCCESS: return {
            ...state,
            showModalStoreSuccess: action.data
        }

        case SET_WAITING_SHOP_RESULT: return {
            ...state,
            waitingShopResult: action.data
        }

    }

    return state;
}

export default pageReducer;