import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { ContactBranchT, PageConfigT } from 'Store/modules/page';
import style from './ContactBranch.module.scss';
import { AppStateT } from 'Store/modules';
import { ClaimT } from 'api/modules/claim';

type MainProps = {
    title?: string,
    footerText?: any,
    claim?: ClaimT,
    hideDirectContact?: boolean,
    transparent?: boolean,
    hrHeader?: boolean,
    noMargin?: boolean,
    branch?: string,
    contactClassName?: string
}

const ContactBranch: React.FC<MainProps> = ({ title, footerText, hideDirectContact, claim, transparent, hrHeader, noMargin, branch: branchProps, contactClassName }) => {

    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const [dataContact, setDataContact] = useState<ContactBranchT|null>(null);
    const { t } = useTranslation();
    const branch = useMemo(() => branchProps || claim?.branch,[branchProps, claim?.branch]);

    useEffect(() => {
        if (pageConfig.branchesContacts[branch]){
            return setDataContact(pageConfig.branchesContacts[branch]);
        }
        if (pageConfig.branchesContacts['DEFAULT']){
            setDataContact(pageConfig.branchesContacts['DEFAULT']);
        }
    }, [pageConfig.branchesContacts, branch]);

    return (
        <div className={cx(style.root, { [style.transparent]: !!transparent, [style.noMargin]: !!noMargin })}>
            {dataContact &&
                <>
                    <div className={style.contact}>
                        {title ? `${title}:` : ''}
                        <div className={cx(style.contactLines, { [style.hr]: !!hrHeader }, contactClassName)}>
                            <div className={style.line}>{dataContact.fullName}</div>
                            <div className={style.line}>{dataContact.address}</div>
                            {!hideDirectContact &&
                                <div className={style.line}>{t('caseDetails.blockedClaimTel')}: <a href={`tel:${dataContact.phoneNumber}`}>{dataContact.phoneNumber}</a></div>
                            }
                            {!hideDirectContact &&
                                <div className={style.line}><a href={dataContact.url}>{dataContact.url}</a></div>
                            }
                            {(dataContact.email && !hideDirectContact) &&
                                <div className={cx(style.line,style.email)}>
                                    {t('caseDetails.blockedClaimEmail')}: <a href={`mail:${dataContact.email}`}>{dataContact.email}</a>
                                </div>
                            }
                        </div>
                        {footerText ? footerText : ''}
                    </div>
                </>
            }
        </div>
    );
}
export default ContactBranch;