import React, { useMemo } from 'react';

import { CLAIM_STATUSES, ShipmentT } from "../../../DataStructure/interfaces";
import { FeedMessageLine } from "../../../DataStructure/Components";
import { getFeedTypeData } from "../../../DataStructure/helpers";

type MainPropsT = {
    t: (data: string, params?: any) => string,
    shipment?: ShipmentT
}

const PickupShipmentInProcess: React.FC<MainPropsT> = ({ t, shipment }) => {

    const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.SHIPMENT_OUT_FOR_DELIVERY, t, shipment ? { shipmentTrackingNumber: `<strong>${shipment.trackingNumber}</strong>`, shipmentCourier: shipment.trackingUrl } : null), [t, shipment]);

    return (
        <>
            <FeedMessageLine
                data={dataShow}
            />
        </>
    );
}
export default PickupShipmentInProcess;