import React, { useMemo } from 'react';

import{ FeedMessageLine } from "../../../DataStructure/Components";
import { CLAIM_STATUSES } from "../../../DataStructure/interfaces";
import { getFeedTypeData } from "../../../DataStructure/helpers";

type MainPropsT = {
    t: (data: string, params?: any) => string
}

// [SOLUTION_PICKUP_COMPLETED]
const PickupCompleted: React.FC<MainPropsT> = ({ t }) => {

    const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.SOLUTION_PICKUP_COMPLETED, t), [t]);

    return (
        <FeedMessageLine
            data={dataShow}
            spaceBottom
            dataOverText={{ text: t('caseStatuses.SOLUTION_PICKUP_COMPLETED') }}
        />
    );
}
export default React.memo(PickupCompleted);