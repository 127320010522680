import React, { useMemo } from 'react';

import { FeedMessageLine } from "../../../DataStructure/Components";
import { CLAIM_STATUSES } from "../../../DataStructure/interfaces";
import { getFeedTypeData } from "../../../DataStructure/helpers";

type MainPropsT = {
    t: (data: string, params?: any) => string,
    airlineName: string
}

// [SOLUTION_REFUND_PROCESS]
const MCByAirline: React.FC<MainPropsT> = ({ airlineName, t }) => {

    const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.SOLUTION_REFUND_PROCESS, t, { airlineName: `<strong>${airlineName}</strong>` }), [t, airlineName]);

    return (
        <>
            <FeedMessageLine
                data={dataShow}
                spaceBottom
            />
        </>
    );
}
export default MCByAirline;