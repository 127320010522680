import { List } from "rsuite";
import cx from 'classnames';
import React from 'react';

import style from './DrawerOption.module.scss';

type MainPropsT = {
    title: string | React.ReactNode ,
    onClick?: (event: any) => void,
    icon?: any,
    isHeader?: boolean,
    isIcoOpacity?: boolean,
    selected?: boolean
}

const DrawerOption: React.FC<MainPropsT> = ({ onClick, title, icon, isHeader, isIcoOpacity, selected }) => {
    return (
        <List.Item onClick={onClick} className={cx(style.root, { [style.clickable]: !!onClick, [style.selected]: selected } )}>
            {icon &&
                <div className={cx(style.icon, { [style.icoOpacity]: isIcoOpacity })}>{icon}</div>
            }
            <div className={cx(style.title, { [style.header]: isHeader })}>{title}</div>
        </List.Item>
    );
}
export default DrawerOption;