import React, { useMemo } from 'react';

import{ FeedMessageLine } from "../../../DataStructure/Components";
import { CLAIM_STATUSES } from "../../../DataStructure/interfaces";
import { getFeedTypeData } from "../../../DataStructure/helpers";

type MainPropsT = {
    t: (data: string, params?: any) => string
}

const PickupSpareParts: React.FC<MainPropsT> = ({ t }) => {

    const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.SOLUTION_PENDING_SPARE_PARTS, t), [t]);

    return (
        <>
            <FeedMessageLine
                data={dataShow}
            />
            
        </>
    );
}
export default PickupSpareParts;