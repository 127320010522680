import { useEffect, useMemo, useState } from 'react';

import { VerdictT } from "api/modules/claim";

export type ResultValueVoucherT = {
    foundVoucher: boolean,
    voucher: number,
    includeAirlines: boolean,
    additionalRabat: boolean,
    rabatValue: number,
    oldValue: number,
    values: {
        voucher: number,
        residualValue: number
    }
}

const defaultValue: ResultValueVoucherT = {
    foundVoucher: false,
    voucher: 0,
    includeAirlines: false,
    additionalRabat: false,
    rabatValue: 0,
    oldValue: 0,
    values: {
        voucher: 0,
        residualValue: 0
    }
}

const useValueVoucher = (verdict: VerdictT, airlineName: string, airlinesWithRabatVoucher: string[]): ResultValueVoucherT => {
    const actionGetVoucher = useMemo(() => {
        if (!verdict) return defaultValue;

        const values: ResultValueVoucherT['values'] = {
            voucher: verdict?.resolution?.voucher || 0,
            residualValue: verdict?.resolution?.residualValue || 0
        }
        const isDifferentValues = values.voucher > 0 && values.residualValue > 0 && values.voucher !== values.residualValue;

        return {
            foundVoucher: true,
            voucher: isDifferentValues && values.voucher > 0 ? values.voucher : values.residualValue,
            includeAirlines: airlinesWithRabatVoucher.includes(airlineName),
            additionalRabat: isDifferentValues,
            rabatValue: isDifferentValues ? Math.abs(values.voucher - values.residualValue) : 0,
            oldValue: isDifferentValues ? (values.voucher > values.residualValue ? values.residualValue : values.voucher) : 0,
            values
        };
    }, [verdict, airlinesWithRabatVoucher, airlineName]);

    const [result, setResult] = useState<ResultValueVoucherT>(actionGetVoucher);

    useEffect(() => {
        setResult(actionGetVoucher);
    }, [actionGetVoucher]);

    return result;
}
export default useValueVoucher;