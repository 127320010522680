import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { FeedMessageLineDataT } from "Components/FeedMessageLine/FeedMessageLine";
import { calculateDateDifference, getAgeName } from "Helpers/date";
import { ResultValueVoucherT } from "Hooks/useValueVoucher";
import useFormattedPrice from "Hooks/useFormattedPrice";
import { PageConfigT } from "Store/modules/page";
import { VerdictT } from "api/modules/claim";
import { AppStateT } from "Store/modules";

const useVoucherData = (verdict: VerdictT, airlineName: string, cost: number, luggageCurrencyIsoCode: string, t: (data: string, params?: any) => string, rabatData: ResultValueVoucherT ) => {

    const [messages, setMessages] = useState<FeedMessageLineDataT>([]);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const formatPrice = useFormattedPrice(verdict.currencyIsoCode, pageConfig.noFractionCurrencies);

    useEffect(() => {
        const _m: FeedMessageLineDataT = [];
        if (airlineName) {
            if (airlineName === 'Lufthansa' || airlineName === 'Deutsche Lufthansa' || airlineName === 'Swiss International Airlines'){
                if (airlineName === 'Deutsche Lufthansa') {
                    _m.push(t('chatMessages.irreparableDescriptionLH1'));
                }
                _m.push(t('chatMessages.irreparableDescriptionLH2'));
            } else {
                _m.push(t('chatMessages.irreparableDescription1'));
                _m.push(t('chatMessages.irreparableDescription2') );
            }
            _m.push(t('chatMessages.irreparableDescription3', { airlineName: `<strong>${airlineName}</strong>` }));
        }
        if (verdict.resolution?.refund && verdict.resolution.refund > 0){
            _m.push(t('chatMessages.irreparableDescription4', { amount: `<span class="price">${formatPrice(+verdict.resolution.refund)}</span>`, currency: `<span class="price">${verdict.currencyIsoCode}</span>` }));
        }
        if (cost && luggageCurrencyIsoCode){
            _m.push(t('chatMessages.valueOfPaxValuation', { amount: `<span class="price">${formatPrice(cost)} ${luggageCurrencyIsoCode}</span>` }));
        }
        if (verdict?.purchaseDate && !!verdict?.createdAt){
            const diffData = calculateDateDifference(verdict?.purchaseDate, verdict?.createdAt);
            _m.push(t('chatMessages.irreparableDescription5', { age: `<strong>${getAgeName((diffData.years * 12) + diffData.months, t)}</strong>` }));
        }
        if (verdict?.value >= 0) {
            _m.push(t('chatMessages.irreparableDescription6', { 
                airlineName: airlineName,
                amount: `<span class="price">${formatPrice(rabatData.values.residualValue)}</span>`,
                currency: `<span class="price">${verdict.currencyIsoCode}</span>`
            }));
        }
        if (airlineName.indexOf('Eurowings') >= 0 && verdict?.resolution?.discountCode && verdict?.resolution?.voucher === 20) {
            _m.push(t('chatMessages.voucherCodeDescription1', { airlineName: airlineName, amount: '20 EUR' }))
        }
        if (airlineName.indexOf('Eurowings') < 0 && verdict?.resolution?.discountCode) {
            _m.push(t('chatMessages.discountCodeDescription1', { percentageAmount: 30}))
            _m.push(t('chatMessages.discountCodeDescription2', { discountCode: `<strong>${verdict.resolution.discountCode}</strong>`}))
        }

        setMessages(_m);
    }, [airlineName, verdict, luggageCurrencyIsoCode, t, formatPrice, rabatData, cost]);

    return messages;
}
export default useVoucherData;