import FeedMessageLine from "Components/FeedMessageLine/FeedMessageLine";
import React, { useCallback } from 'react';

import { UploadIcoPdf } from "PageComponents/Feed/DataStructure/SvgData";
import { ImageDecrypt } from "../../../DataStructure/Components";
import style from './RepairBudgetCompleted.module.scss';
import { runNotify } from 'Helpers/notifications';
import { ClaimFileT } from "api/modules/claim";
import { getUrlOfImage } from "services/api";

type MainPropsT = {
    t: (data: string, params?: any) => string,
    disabled: boolean,
    photos: ClaimFileT[]
}

const RepairBudgetCompleted: React.FC<MainPropsT> = ({ disabled, t, photos }) => {
    
    const downloadInfo = useCallback((documentKey: string) => {
        getUrlOfImage(documentKey, (result: boolean, url: string|undefined) => {
            if (result && url){
                window.open(url);
            } else {
                runNotify({ message: 'Error while trying to download the file', type: 'error' });
            }
        });
    }, []);

    return (
        <>
            {photos.length > 0 &&
                <FeedMessageLine
                    data={(
                        <div className={style.root}>
                            {t('chatMessages.sparePartsSendedPhoto')}
                            <div className={style.photosLayer}>
                                {photos.map(photo => (
                                    <div className={style.photo} key={`ph-${photo.original.key}`}>
                                        {photo.name?.endsWith('.pdf') ?
                                            <div className={style.pdfIconContainer} onClick={() => downloadInfo(photo.name)}>
                                                <UploadIcoPdf className={style.pdfIcon} />
                                            </div>
                                            :
                                            <ImageDecrypt imageKey={photo.thumbnail.key} />
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    float={'right'}
                    showAvatar={false}
                    hideAvatar
                />
            }
        </>
    );
}
export default RepairBudgetCompleted;