import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import React from 'react';

import { AppStateT, FeedMessageLineDataT, PageConfigT, VerdictT } from "../../../DataStructure/interfaces";
import{ FeedMessageLine } from "../../../DataStructure/Components";
import { useFormattedPrice } from "../../../DataStructure/hooks";
import AnimatedVerdict from "./AnimatedVerdict/AnimatedVerdict";
import style from './AssessmentVerdictAccessShop.module.scss';
import useValueVoucher from "../../../Hooks/useValueVoucher";
import useVoucherData from "../../../Hooks/useVoucherData";

type MainPropsT = {
    t: (data: string, params?: any) => string,
    disabled?: boolean,
    verdict: VerdictT,
    airlineName: string,
    luggageCost: number,
    luggageCurrencyIsoCode: string
}

const AssessmentVerdictAccessShop: React.FC<MainPropsT> = ({ t, disabled, verdict, airlineName, luggageCost, luggageCurrencyIsoCode }) => {

    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const formatPrice = useFormattedPrice(verdict.currencyIsoCode, pageConfig.noFractionCurrencies);
    const rabatData = useValueVoucher(verdict, airlineName, pageConfig.airlinesWithRabatVoucher);
    const dataMessagesVoucher = useVoucherData(verdict, airlineName, luggageCost, luggageCurrencyIsoCode, t, rabatData);

    const data: FeedMessageLineDataT = [
        t('chatMessages.verdictReady'),
        <>
            {t('chatMessages.caseIs')} {' '}
            {verdict?.verdictType &&
                <strong>{t(`verdictTypes.${verdict.verdictType}`)}</strong>
            }
            {verdict?.verdictType === 'irreparable' ? t('chatMessages.caseIsExtend') : ''}
        </>,
    ];

    return (
        <div className={style.root}>
            <FeedMessageLine
                data={data}
                spaceBottom
                disabled={disabled}
            />

            {/* Show data voucher */}
            <FeedMessageLine
                data={dataMessagesVoucher}
                spaceBottom
                dataOverText={{ text: t('caseStatuses.ASSESSMENT_SELECTION'), placeInLastCloud: true }}
                disabled={disabled}
            />

            {/* ico voucher */}
            <FeedMessageLine
                data={(
                    <div className={style.icoValue}>
                        <AnimatedVerdict />
                        <div className={style.value}>
                            {parse(t('common.valueWithCurrency', {
                                value: `<span class="price">${formatPrice(rabatData.values.residualValue)}</span>`,
                                currency: `<span class="price">${verdict.currencyIsoCode}</span>`,
                            }))}
                        </div>
                        <div className={style.text}>
                            {t('chatMessages.valueOfRefund')}
                        </div>
                    </div>
                )}
                transparent
                hideAvatar
                disabled={disabled}
                center
            />

            {rabatData.additionalRabat &&
                <FeedMessageLine
                    data={t('chatMessages.plus8RabatInfo', { amount_old: formatPrice(rabatData.oldValue), currency: luggageCurrencyIsoCode, amount: `<span class="price">${formatPrice(rabatData.voucher)} ${luggageCurrencyIsoCode}</span>` })}
                    disabled={disabled}
                />
            }

        </div>
    );
}
export default AssessmentVerdictAccessShop;