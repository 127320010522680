import React, { useEffect } from 'react';

import { ReactComponent as WarningBtn } from 'Assets/report.svg';

import useTranslate from "Hooks/useTranslate";
import { StepsE } from "Store/Enums/claim";
import style from './InfoImages.module.scss';

type MainProps = {
    setStepHidden: (step: StepsE, hidden?: boolean) => void,
}

const InfoImages: React.FC<MainProps> = ({ setStepHidden }) => {

    const t = useTranslate();

    useEffect(() => {
        setStepHidden(StepsE.WARNING_ATTACHMENTS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={style.root}>

            <div className={style.warningIco}>
                <WarningBtn />
            </div>

            <div className={style.info}>
                {t('reportProblem.infoImagesWarning')}
            </div>
        </div>
    );
}
export default React.memo(InfoImages);