import { useEffect, useMemo, useState } from 'react';

import { ClaimHistoryT } from "api/modules/case-resolution";
import { ClaimT } from "api/modules/claim";

const useHistory = (claim: ClaimT) => {

    const actionSetHistory = useMemo(() => (): ClaimHistoryT[] => {
        const status: ClaimHistoryT[] = [];
        claim?.statusHistory?.forEach(st => {
            status.push({
                report: claim?.reports?.find(report => report.claimStatusId === st.id) || null,
                verdict: claim?.verdict || null,
                status: st,
                resolution: claim?.resolutions ? (claim?.resolutions as any[])?.find(ar => ar.claimStatusId === st.id) || null : null
            })
        });
        return status;
    }, [claim?.verdict, claim?.reports, claim?.resolutions, claim?.statusHistory])

    const [history, setHistory] = useState<ClaimHistoryT[]>(actionSetHistory());

    useEffect(() => {
        setHistory(actionSetHistory());
    }, [claim?.reports, claim?.resolutions, claim?.statusHistory, actionSetHistory]);

    return history;
}
export default useHistory;