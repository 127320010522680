import React, { useCallback } from 'react';

import { Button, FeedMessageLine } from "../../../DataStructure/Components";

type MainPropsT = {
    t: (data: string, params?: any) => string,
    moneyCompensationUrl: string,
    disabled: boolean
}

const MCDataEntry: React.FC<MainPropsT> = ({ t, disabled, moneyCompensationUrl }) => {

    const actionPressButton = useCallback((url: string) => {
            window.open(url);
    }, []);

    return (
        <>
            {moneyCompensationUrl &&
                <FeedMessageLine
                    data={
                        <>
                            <Button onClick={() => actionPressButton(moneyCompensationUrl)} disabled={disabled}>{t('chatMessages.formMCSubmit')}</Button>
                        </>
                    }
                    transparent
                    showAvatar={false}
                    disabled={disabled}
                />
            }
        </>
    );
}
export default MCDataEntry;