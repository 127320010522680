import { useCallback, useMemo, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StoreModal } from 'PageComponents/LuggageStore';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash.throttle';
import cx from 'classnames';

import { ReactComponent as PlusIcon } from 'Assets/plus.svg';
import BusinessCaseIcon from 'Assets/business-case.svg';
import DrawerMenu from 'Components/DrawerMenu/DrawerMenu';
import ChatBotIcon from 'Assets/chat-bot.svg';

import { StateT as PageStateT, setClaimArchived, setMessageClaim } from "Store/modules/page";
import { setLoadingClaims, type StateT as ClaimListStateT } from 'Store/modules/claim';
import ClaimBlockedOnChat from "Components/ClaimBlockedOnChat/ClaimBlockedOnChat";
import type { StateT as ClaimDetailsStateT } from 'Store/modules/claimDetails';
import type { AccountSettingsValueT } from 'Store/modules/accountSettings';
import { CaseList, Page, Tab, LoadableContent, Button } from 'Components';
import ClaimMessage from "PageComponents/ClaimMessage/ClaimMessage";
import { setAccountSettings } from 'Store/modules/accountSettings';
import { clearDataStore } from "Store/modules/luggageStore/shop";
import { ProfileSettingsModal } from 'PageComponents/Settings';
import { helpCenterAvailableLanguages } from 'Helpers/i18';
import ClaimDetails from "./ClaimDetails/ClaimDetails";
import styles from './ClaimListMobile.module.scss';
import ChatHeader from "./ChatHeader/ChatHeader";
import ChatFooter from "./ChatFooter/ChatFooter";
import Contact from "Components/Contact/Contact";
import useSalutation from "Hooks/useSalutation";
import { userNameJoin } from 'Helpers/strings';
import Feed from "PageComponents/Feed/Feed";
import { ClaimT } from "api/modules/claim";
import { AppStateT } from "Store/modules";
import { iPhone } from "Helpers/browser";
import { UserT } from 'api/modules/user';


type MainPropsT = {
    user: UserT | null;
    claimList: ClaimListStateT;
    openClaimDetails: (id: string) => void;
    claimDetails: ClaimDetailsStateT;
    closeClaimDetails: () => void;
    actionParam: string | undefined,
    archive: boolean,
    claim: ClaimT | null
};

const OPEN_CASES_TAB = 'OPEN_CASES_TAB';
const CLOSED_CASES_TAB = 'CLOSED_CASES_TAB';

export const mobileHeaderHeight = 80;
export const mobileFooterHeight = 80;

function MobileClaimListHeader(props: any) {
    const { t } = useTranslation();
    const [shortVersion, setShortVersion] = useState(false);
    const logoRef = useRef<HTMLImageElement | null>(null);
    const locUrl = useLocation();
    const navigate = useNavigate();
    

    useEffect(() => {
        if (locUrl.pathname === '/createDesktop') {
            navigate('/create');
        }
    }, [locUrl, navigate]);

    useLayoutEffect(() => {
        function calculate() {
            if (!shortVersion && document.documentElement.scrollTop > 130) {
                return setShortVersion(true);
            }
            if (shortVersion && document.documentElement.scrollTop === 0) {
                return setShortVersion(false);
            }
        }

        const throttledCalculate = throttle(calculate, 50);

        document.addEventListener('scroll', throttledCalculate, { passive: true });
        throttledCalculate();

        return () => {
            document.removeEventListener('scroll', throttledCalculate);
        };
    }, [shortVersion, setShortVersion]);

    return (
        <div className={cx(styles.mobileClaimListHeader, { [styles.short]: shortVersion })}>
            <img className={styles.botIcon} src={ChatBotIcon} ref={logoRef} alt="" />

            <div className={styles.actions}>{props.actions}</div>

            <div className={styles.message}>
                <span className={styles.title}>{props.title}</span>
                <span className={cx(styles.subtitle, { [styles.short]: shortVersion })}>{props.subtitle}</span>
            </div>

            <div className={styles.tabRow}>
                <Tab
                    onClick={props.setCurrentTab(OPEN_CASES_TAB)}
                    active={props.currentTab === OPEN_CASES_TAB}
                    className={styles.tab}
                >
                    {t('claimList.openCasesTab')}
                </Tab>

                <Tab
                    onClick={props.setCurrentTab(CLOSED_CASES_TAB)}
                    active={props.currentTab === CLOSED_CASES_TAB}
                    className={styles.tab}
                >
                    {t('claimList.closedCasesTab')}
                </Tab>
            </div>
        </div>
    );
}

export type MobileTabContentT = 'actual' | 'details' | 'chat' | 'contact';

const ClaimListMobile: React.FC<MainPropsT> = ({ user, claimList, openClaimDetails, claimDetails, actionParam, archive, claim }) => {
    const dispatch = useDispatch();
    const { messageCase } = useSelector<AppStateT, PageStateT>((state) => state.page);
    const [accountSettingsIsOpen, setAccountSettingsIsOpen] = useState<boolean>(false);
    const [tabContent, setTabContent] = useState<MobileTabContentT>('actual');
    const [currentTab, setTab] = useState<string>(OPEN_CASES_TAB);
    const userTitle = useSalutation(user);
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
    const params = useParams<any>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [openStore, setOpenStore] = useState(false);
    const [open, setOpen] = useState(false);
    const toggleDrawer = (newOpen: boolean) => setOpen(newOpen);
    
    const HAMBURGER_ELEMENTS = useMemo(() => [
        ...(actualLanguage === 'pl' || actualLanguage === 'en-us' ? [{ name: t('common.helpCenter'), path: '/help' }] : []),
        { name: t('common.personalInformation'), path: '/personalInfo' },
        { name: 'footer.privacyPolicy', path: '/privacyPolicy' },
        { name: 'footer.privacySettings', path: '/privacy' },
        { name: 'footer.language', path: '/lang' },
        { name: 'footer.termsAndConditions', path: '/terms' },
        { name: t('common.logOut'), path: '/logOut' },
    ], [actualLanguage, t]);  

    const footerLinksFirstRow = useMemo(() => 
        HAMBURGER_ELEMENTS.filter(link => link.name === 'footer.help' ? helpCenterAvailableLanguages.includes(actualLanguage) : true), 
    [HAMBURGER_ELEMENTS, actualLanguage]);  

    useEffect(() => {
        setTab(!archive ? OPEN_CASES_TAB : CLOSED_CASES_TAB)
    }, [archive]);

    useEffect(() => {
        if (!(params && params?.id && claim?.id)) {
            setTabContent('actual');
        }
    }, [params, params?.id, claim?.id]);

    useEffect(() => {
        if (params?.action === 'contact'){
            return setTabContent('contact');
        }
        if (params?.action === 'current'){
            return setTabContent('actual');
        }
        if (params?.action === 'details'){
            return setTabContent('details');
        }
    }, [params]);

    const setCurrentTab = useCallback((tab: string) => () => {
        dispatch(setLoadingClaims(true));
        dispatch(setClaimArchived(tab !== OPEN_CASES_TAB));
        navigate(`/${tab === CLOSED_CASES_TAB ? 'archive' : ''}`);
        setTab(tab);
    }, [setTab, dispatch, navigate]);

    const cases = useMemo(() => claimList.data.filter(({ closed }) => closed === (currentTab !== OPEN_CASES_TAB)), [currentTab, claimList]);

    const navigateToCreationScreen = useCallback(() => {
        navigate('/create');
    }, [navigate]);

    function setSettings(value: AccountSettingsValueT) {
        dispatch(setAccountSettings(value));
    }

    const actionOpenStore = useCallback(() => {
        setOpenStore(true);
    }, []);

    const actionChange = useCallback((data: MobileTabContentT) => {
        setTabContent(data);
        if (data === 'chat') {
            navigate(`/${claim.id}`);
            dispatch(setMessageClaim(claim));
        } else {
            if (data === 'actual') return navigate(`/${claim.id}/current`);
            if (data === 'contact') return navigate(`/${claim.id}/contact`);
            if (data === 'details') return navigate(`/${claim.id}/details`);
        }
    }, [claim, dispatch, navigate]);

    const actionCloseModal = useCallback(() => {
        dispatch(clearDataStore());
        setOpenStore(false);
    }, [dispatch]);

    const actionBack = useCallback(() => {
        navigate(`/${archive ? 'archive/' : ''}`);;
    }, [archive, navigate])

    return (
        <Page>
            {(params && params?.id && !!claim) ?
                <div className={styles.containerMain}>
                    <ChatHeader claim={claim} t={t} actionBack={actionBack} />
                    <div
                        className={cx(
                            styles.chatMain, {
                                [styles.chatContact]: tabContent === 'chat' && messageCase,
                                [styles.iphone]: iPhone(),
                                [styles.feed]:  tabContent === 'actual' && !claim.isBlocked
                            })}
                        style={{
                            height: `calc(100vh - ${mobileHeaderHeight + mobileFooterHeight}px)`,
                            top: mobileHeaderHeight
                        }}
                    >
                        {tabContent === 'actual' ?
                            <>
                                {claim.isBlocked ?
                                    <ClaimBlockedOnChat claim={claim} /> :
                                    <Feed
                                        claimId={params.id}
                                        openFromMobile
                                        claim={claim}
                                        actionParam={actionParam}
                                        openStore={actionOpenStore}
                                    />
                                }
                            </> :
                            <>
                                {tabContent === 'details' ?
                                    <ClaimDetails claim={claim} t={t} key={`c-${claim.id}`} /> :
                                    <>
                                        {tabContent === 'contact' ?
                                            <Contact /> :
                                            <>
                                                {messageCase && <ClaimMessage noModal height={`calc(100vh - ${mobileHeaderHeight + mobileFooterHeight + (iPhone() ? 80 : 0)}px)`} />}
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                    <ChatFooter claim={claim} t={t} actionChange={actionChange} tabContent={tabContent} />
                </div> :
                <>
                    <MobileClaimListHeader
                        title={t('claimList.titleMobile', { name: userNameJoin(user, userTitle.salutation, userTitle.title) })}
                        subtitle={t('claimList.subtitleMobile')}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        actions={
                            <>
                                <button className="header-action" onClick={navigateToCreationScreen} style={{ backgroundColor: '#2fc3dd' }} id="button_new_claim_mobile">
                                    <PlusIcon className={styles.plusIcon} />
                                </button>
                                <div className={styles.menuDiv}>
                                    <DrawerMenu
                                        open={open}
                                        toggleDrawer={toggleDrawer}
                                        footerLinksFirstRow={footerLinksFirstRow}
                                    />
                                </div>
                            </>
                        }
                    />
                    <LoadableContent loading={claimList.loading || claimDetails.loading}>
                        {cases.length === 0 && (
                            <div className={styles.noClaims}>
                                <img src={BusinessCaseIcon} alt="" className={styles.businessIcon} />

                                {currentTab === OPEN_CASES_TAB ? (
                                    <>
                                        <span>{t('claimList.noOpenClaimP1')}</span>
                                        <span>{t('claimList.noOpenClaimP2')}</span>
                                    </>
                                ) : (
                                    <span>{t('claimList.noClosedClaim')}</span>
                                )}
                            </div>
                        )}

                        {cases.length >= 1 && <CaseList data={cases} onSelect={openClaimDetails} />}
                    </LoadableContent>

                    <ProfileSettingsModal
                        open={accountSettingsIsOpen}
                        onClose={() => {
                            setAccountSettingsIsOpen(false);
                            setSettings('');
                        }}
                    />
                    {currentTab === OPEN_CASES_TAB && (
                        <div className={styles.buttonContainer}>
                            <Button className={styles.button} onClick={navigateToCreationScreen}>
                                {t('claimList.makeClaim')}
                            </Button>
                        </div>
                    )}
                </>
            }
            {openStore &&
                <StoreModal
                    claim={claim}
                    onClose={actionCloseModal}
                    open
                />
            }
        </Page>
    );
}

export default ClaimListMobile;
