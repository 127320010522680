import React, { useCallback, useMemo, useState } from 'react';
import parse from 'html-react-parser';
import cx from 'classnames';

import { RequiredDocumentT } from 'PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm';
import ReportUploadBox, { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import ExternalnfoData from "Components/ExternalnfoData/ExternalnfoData";
import { MissingDocExampleT } from "Store/modules/claim";
import { generateId } from 'Helpers/strings';
import style from './DropImage.module.scss';
import Ico from 'Components/Ico/Ico';

type PropsMainT = {
    type: string,
    images: IImage[],
    allImages: IImage[],
    t: (data: string, param?: any) => string,
    setImages: (type: string, data: IImage[]) => void,
    maxImages?: number,
    documentRequire?: RequiredDocumentT,
    downloadDocument?: (doc: RequiredDocumentT) => void,
    title: string,
    allowPdf?: boolean,
    exampleData: MissingDocExampleT|null
    id?: string,
    disabled?: boolean
}

const DropImage: React.FC<PropsMainT> = ({ type, images, setImages, maxImages = 3, allImages, documentRequire, downloadDocument, t, title, allowPdf, exampleData, id, disabled }) => {

    const [showExamples, setShowExamples] = useState(false);

    const removeDamageImage = (image: IImage) => {
        setImages(type, images.filter(im => im.url !== image.url));
    }

    const actionSetImage = (image: IImage) => {
        setImages(type, [...images, ...[image]]);
    }

    const getTitle = useMemo(() => (): JSX.Element => {

        if (!!documentRequire) return (
            <div className={style.airlineDocument}>
                {parse(t('report.reportReqDoc', { documentName: `<strong>${documentRequire.Name}</strong>` }))}{' '}
                <span onClick={() => downloadDocument?.(documentRequire)}>{t('report.downloadNow')}</span>, {t('report.downloadNowNext')}
            </div>
        );
        
        return <>{title}</>;
    }, [documentRequire, downloadDocument, t, title])

    const actionOpenExample = useCallback(() => {
        setShowExamples(old => !old);
    }, []);

    return (
        <div className={cx(style.root, { [style.success]: images && images.length > 0 })}>
            <div className={style.title}>
                <span>
                    {getTitle()}
                </span>
                {exampleData &&
                    <div className={style.icoTooltip}>
                        <Ico action={() => actionOpenExample()} />
                    </div>
                }
            </div>
            <div className={style.images}>
                {images.map((im, idx) => {
                    return (
                        <div className={style.putImage} key={`file-${im.url}`}>
                            <ReportUploadBox
                                value={im}
                                removable={true}
                                onRemove={() => removeDamageImage(im)}
                                name={`photo-report-${idx}`}
                                hideName
                                // excludeImages={images.map(ph => ph.images)}
                                fitWidth
                                onlyImages={!!!documentRequire}
                                disabled
                                id={id ? `${id}_${idx}` : null}
                            />
                        </div>
                    )
                })}
                {images.length < maxImages &&
                    <div className={style.putImage}>
                        <ReportUploadBox
                            value={null}
                            removable={true}
                            onChange={(value) => actionSetImage(value)}
                            name={allowPdf ? 'missingDocument-photo-report-0' : 'photo-report-0'}
                            hideName
                            excludeImages={allImages}
                            fitWidth
                            onlyImages={!allowPdf}
                            addMode
                            key={`add-${type}-${generateId()}`}
                            id={id}
                            disabled={disabled}
                        />
                    </div>
                }
            </div>
            {showExamples &&
                <div className={style.examplesExternalInfo}>
                    <ExternalnfoData
                        t={t}
                        missingType={exampleData.SFTag}
                        missingDocumentsAdditionalInfo={exampleData}
                    />
                </div>
            }
        </div>
    );
}
export default DropImage;