import React from 'react';

import style from './ReadingOcr.module.scss';
import { Loader } from "Components";

type MainPropsT = {
    t: (data: string, params?: any) => string
}

const ReadingOcr: React.FC<MainPropsT> = ({ t }) => {
    return (
        <div className={style.root}>
            <div className={style.text}>
                {t('createClaim.ocrReadText')}
            </div>
            <Loader />
        </div>
    );
}
export default ReadingOcr;