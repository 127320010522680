import React, { useState, useCallback } from 'react';

import { ReactComponent as UploadIcoDoc } from 'Assets/upload-doc.svg';
import { ReactComponent as UploadIcoPdf } from 'Assets/upload-pdf.svg';

import { AIRPORT_DOCUMENT } from "../../ReportProblemDataEntry/ReportProblem";
import FeedMessageLine from "Components/FeedMessageLine/FeedMessageLine";
import ImageDecrypt from "Components/ImageDecrypt/ImageDecrypt";
import { type StateT as UserStateT } from 'Store/modules/user';
import { runNotify } from "Helpers/notifications";
import Loading from "Components/Loading/Loading";
import { getUrlOfImage } from "services/api";
import style from './Resolution.module.scss';
import { getFileData } from 'Helpers/files';
import { sizeAdapter } from 'Helpers/claim';

type MainPropsT = {
    t: (data: any) => string,
    resolution: ReportT['resolution'],
    tagToNameData: { [key in string]: string },
    user: UserStateT['data'],
}

const Resolution: React.FC<MainPropsT> = ({ t, resolution, tagToNameData, user }) => {
    const messages = [t('report.userResponseMessage')];
    const [loadingDocumentKey, setLoadingDocumentKey] = useState<string>('');

    const downloadInfo = useCallback((documentKey: string) => {
        setLoadingDocumentKey(documentKey);
        getUrlOfImage(documentKey, (result: boolean, url: string|undefined) => {
            setLoadingDocumentKey('');
            if (result && url){
                window.open(url);
            } else {
                runNotify({ message: t('shop.errorAddClaim'), type: 'error' });
            }
        });
    }, [t]);

    const getTagValue = useCallback((res: any) => {
        if (res.tag === 'SIZE') {
            return sizeAdapter(res.input, user.location.countryCode, user.language)
        } else if (res.tag.indexOf('_ORIGINAL') >= 0) {
            return t('reportTags.deliveredOriginal')
        } else {
            return res.input
        }
    }, [user.location.countryCode, user.language, t])

    const DataTag = (res: any, fileDocumentName: string|null) => {

        return (
            <>
                <div className={style.tagTitle}>
                    {t(tagToNameData[res.tag])}
                    {fileDocumentName &&
                        <strong>: {fileDocumentName}</strong>
                    }
                </div>
                {res?.files && Array.isArray(res.files) ?
                    <div className={style.tagPhotos}>
                        {res.files.map((file: any) => {
                            const dataImage = getFileData(file.url);
                            if (!(dataImage.ext === 'jpg' || dataImage.ext === 'jpeg' || dataImage.ext === 'png' || dataImage.ext === 'bmp' || dataImage.ext === 'gif' || dataImage.ext === 'webp')){
                                if (dataImage.ext === 'pdf'){
                                    return (
                                        <React.Fragment key={`k-${file.key}`}>
                                            {file.key === loadingDocumentKey ?
                                                <Loading size={40} />:
                                                <UploadIcoPdf className={style.thumbSvg} onClick={() => downloadInfo(file.key)} />
                                            }
                                        </React.Fragment>
                                    )
                                }
                                return <UploadIcoDoc className={style.thumbSvg} key={`k-${file.key}`} />
                            } else return (
                                <div key={`im-${file.url}`} className={style.image}>
                                    <ImageDecrypt imageKey={file.key} key={`k-${file.key}`} />
                                </div>
                            )
                        })}
                    </div>:
                    <div className={style.tagValue}>{getTagValue(res)}</div>
                }
            </>
        );
    }

    return (
        <div className={style.chatMessage}>
            <FeedMessageLine
                data={[...messages, ...resolution.map((res, idx) => {
                    const fileDocumentName = 
                            res.tag === AIRPORT_DOCUMENT
                        ?
                            res.tag
                        : null;
                    return DataTag(res, fileDocumentName);
                })]}
                showAvatar={false}
                float={'right'}
                hideAvatar
            />
        </div>
    );
}
export default React.memo(Resolution);