import { RequiredDocumentT } from 'PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm';
import { ClaimProgressStageE, ClaimResolutionSourceEnumE } from "Store/Enums/claim";
import { DeliveryMethodT } from 'Store/modules/luggageStore/shop';
import { ShipmentTypesEnum } from "Store/Enums/shipments";
import type { ResolutionT } from './case-resolution';
import { CartDataSendT } from 'Helpers/useDataCart';
import { CLAIM_STATUSES } from 'Config/statuses';
import type { ReqTypeT, ResTypeT } from '../';
import API from '../base/api';

export type MediaItem = {
    img: string;
    name: string;
};

type FormCaseT = {
    media: MediaItem[];
    note: string;
};

export type ClaimStatusT = {
    report: ReportT|null,
    resolution: ResolutionVerdictT|null,
    verdict: VerdictT|null,
    status: ClaimStatusHistoryItemT
}

export type CreateClaimFormDataT = {
    fileReference: string;
    location: LocationT;
    suitcases: FormCaseT[];
};

export type CaseT = {
    files: {
        photos: ClaimFileT[], 
        documents: ClaimFileT[]
    };
    luggageType: string,
    review?: {
        googleMapsUrl?: string,
        trustPilotUrl?: string
    }
};

export type ClaimStatusHistoryItemT = {
    claimId: string;
    createdAt: string;
    id: string;
    status: CLAIM_STATUSES;
    operationalStatus: string;
    isNotification: boolean;
};

export type VerdictT = {
    age: number,
    stage?: string;
    caseType: string;
    verdictType: string;
    value: number;
    claimId: string;
    id: string;
    createdAt: string;
    purchaseDate?: string,
    source: ClaimResolutionSourceEnumE,
    currencyIsoCode: string,
    resolution: {
        repair: boolean,
        voucher: number,
        refund: number,
        residualValue: number,
        moneyCompensation: boolean,
        moneyCompensationUrl?: string,
        oneToOneReplacement: boolean,
        sparePartsPaxValidation: boolean,
        sparePartsResolution: boolean,
        discountCode?: string,
        responseData: {
            sparePartsPhotos?: {
                name: string,
                original: {
                    key: string,
                    url: string
                },
                thumbnail: {
                    key: string,
                    url: string
                },
                type: string
            }[],
            sparePartsSendRefusal: boolean,
        }
    };
    selectedResolution: "cashRefund" | "pick-up" | "shop"; // from Salesforce
    claimStatusId?: string;
    isActive?: boolean;
    isSelected?: boolean;
};

export type ClaimFileT = {
    name: string,
    type: string,
    thumbnail: {
        url: string,
        key: string,
    },
    original: {
        url: string,
        key: string,
    },

}

export enum OrderStatusE {
    DRAFT = 'DRAFT',
    ACTIVATED = 'ACTIVATED',
    ORDERED_DROPSHIPPING = 'ORDERED_DROPSHIPPING',
    DEACTIVATED = 'DEACTIVATED',
    COMPLETED = 'COMPLETED',
    FULFILLED = 'FULFILLED',
    ORDER_IN_PROCESS = 'ORDER_IN_PROCESS'
}

export type OrderProductT = {
    amount: number,
    color: string,
    deliveryDays: string,
    manufacturer: string,
    material: string,
    name: string,
    photoUrl: string,
    quantity: string,
    size: string,
    weight: string,
    status?: OrderStatusE
}

export type OrderT = {
    amount: number,
    amountTotal: number,
    amountVoucher: number,
    discountAmount: number,
    discountRabat: number,
    orderNumber: string,
    currency: string,
    payment: {
        selectedType: string,
        status: string,
        redirectUrl?: string
    },
    products: OrderProductT[],
    salesforceCaseNumber: string,
    salesforceOrderNumber: string,
    id: string,
    shipment: {
        email: string,
        firstName: string,
        lastName: string,
        location: {
            address: string,
            city: string,
            coords: {
                lat: string,
                lon: string,
            },
            country: string,
            countryCode: string,
            formattedAddress: string,
            zipCode: string,
        },
        phoneNumber: string,
        deliveryMethod: DeliveryMethodT
    },
    status: OrderStatusE,
    createdAt: string
}

type ShopT = {
    storeView: string,
    currency: string,
    cart: CartDataSendT,
}

type LuggageT = {
    brand: string,
    capacity: number,
    color: string,
    cost: number,
    currencyIsoCode: string,
    damages: { type: string, side: string }[],
    date: string,
    functions: string,
    height: number,
    length: number,
    material: string,
    model: string,
    serialNumber: string,
    tagNumber: string,
    type: string,
    width: number
}

export type ResolutionVerdictT = {
    claimId: string,
    claimStatusId: string,
    createdAt: string,
    id: string,
    orderData: {
        type: string,
    },
    resolutionData: {
        sparePartsSendRefusal?: boolean,
        apt?: string,
        courierNotes?: string,
        date?: string,
        email?: string,
        extraService?: {
            salesforceId: string, 
            payment: {
                selectedType: string,
                status: string
            },
            service: string,
            cost: number,
            currencyIsoCode: string
        },
        leaveAt?: string,
        location?: {
            country: string, 
            zipCode: string,
            address: string,
            city: string,
            formattedAddress: string,
            coords: {
                lng: number,
                lat: number
            }
        },
        name?: string,
        phoneNumber?: string,
        time?: string,
    },
    status: string,
    type: string
}

export type ChatDataT = {
    id: string,
    createdAt: string,
    message: string,
    type: 'PAX'|'ASSESSOR'|'INTERNAL',
    isHidden: boolean
}
export interface ClaimChatMessagesT {
    claimId: string,
    createdAt: string,
    id: string,
    messages: ChatDataT[],
    unreadedMessagesByPax: number,
    unreadedMessagesByAssessor: number,
    updatedAt: string
}
export interface WebsocketChatMessageT {
    claimId: string,
    message: ChatDataT
}

export type ShipmentT = {
    claimId: string,
    courierCompanyName: string,
    createdAt: string,
    id: string,
    orderId: string,
    shipmentType: ShipmentTypesEnum,
    trackingNumber: string,
    trackingUrl: string,
    updatedAt: string
}

export interface IClaimFeedEntry {
    createdAt: string;
    id: string;
    isHidden?: boolean;
    isDisabled?: boolean;
    stage: ClaimProgressStageE
    readAt?: string,
    validUntil?: string,
    values?: any
}

export interface IClaimFeed {
    claimId: string;
    createdAt?: string;
    entries: IClaimFeedEntry[];
    id: string;
    unreadEntries: number,
    updatedAt?: string;
    userId: string;
}

export type ClaimT = {
    referenceNumber: string;
    closed: boolean;
    userId: string;
    status: string;
    branch: string;
    airlineName: string,
    airlineId: string,
    airlineDateLanding: string,
    operationalStatus: string;
    blockReason?: 'TK_GERMANY_POSTAL_CODE'|undefined|null;
    createdAt: string;
    claimCreatedAt?: string;
    id: string;
    isBlocked: boolean,
    isArchived?: boolean,
    location: LocationT;
    case: CaseT;
    shop: ShopT;
    feed: IClaimFeed|null;
    claimStatus?: ClaimStatusHistoryItemT[];
    catalogs?: string[],
    unreadCount: number;
    caseNumber: any;
    currencyIsoCode: string;
    verdict: VerdictT | null;
    reports: ReportT[];
    luggage: LuggageT,
    luggageInput: LuggageT,
    assessmentCreatedAt: string;
    resolutionData?: ShipmentUpdateT | null;
    isAssessmentTimeOut?: boolean;
    statusHistory?: ClaimStatusHistoryItemT[];
    // assessmentResults: assessmentResult[],
    pdfRaport?: {
        key: string,
        url: string
    },
    orders: OrderT[]|null,
    requiredDocuments: RequiredDocumentT[] | null,
    resolutions?: ResolutionVerdictT[],
    shipments?: ShipmentT[]
};

export type ShipmentUpdateT = {
  status: string;
  createdAt: string;
  claimId: string;
  orderData: { type: string };
  id: string;
  resolutionData: {
    date: string;
    leaveAt: string;
    apt: string;
    name: string;
    location: LocationT;
    courierNotes: string;
    time: string;
    shipment: {
      courier: string;
      trackingNumber: string;
      link: string;
    };
  };
  type: string;
};

export type AssessmentResultT = {
  type: string;
  content: VerdictT;
};

export type ClaimDetailsT = ClaimT & {
  verdict: VerdictT | null;
  resolution: ResolutionT<any> | null;
  assessmentResults?: VerdictT[]
};

export type ReviewT = {
  claimId: string;
  rating: string;
  tags: string[];
};

export class ClaimApi extends API {
    list = (): ResTypeT<ClaimT[]> => {
        return this.r({
            method: 'GET',
            url: '/claim',
        });
    };

    archiveList = (): ResTypeT<ClaimT[]> => {
        return this.r({
            method: 'GET',
            url: '/archive/claim',
        });
    };

    getById = (id: string, isArchived: boolean): ResTypeT<ClaimDetailsT> => {
        if (!id) throw new Error('Invalid claim ID passed to getById');
        return this.r({
            method: 'GET',
            url: `${isArchived ? '/archive' : ''}/claim/${id}`,
        });
    };

    getAssessmentResultById = (id: string): ResTypeT<AssessmentResultT> => {
        return this.r({
            method: 'GET',
            url: `/claim/${id}/final`,
        });
    };

    create = (claim: FormData) => {
        console.log('XX create claim: ', claim);
        return this.r({
            method: 'POST',
            url: `/claim`,
            data: claim,
        });
    };

    addRefNumberToCheckQueue = (referenceNumber: string) => {
        return this.r({
        method: 'POST',
        url: `/claim/check`,
        data: {
            // @ts-ignore
            subscription: window.pushSubscription,
            referenceNumber: referenceNumber,
        },
        });
    };

    readAll = (claimId: string, isArchived: boolean) => {
        return this.r({
            method: 'PUT',
            url: `${isArchived ? '/archive' : ''}/claim/${claimId}/messages/clear-unread`,
        });
    };

    report = (claimId: string, id: string, data: FormData) => {
        return this.r({
            method: 'POST',
            url: `/claim/${claimId}/report/${id}`,
        data,
        });
    };

    review = (claimId: string, review: Pick<ReviewT, 'rating' | 'tags'>) => {
        return this.r({
            method: 'POST',
            url: `/claim/${claimId}/rate`,
            data: review,
        });
    };

    download = (claimId: string) => {
        return this.r({
            method: 'GET',
            url: `/claim/${claimId}/pdf`,
            responseType: 'arraybuffer',
        });
    };

    getMissingDocuments = (claimId?: string|undefined) => {

        if (claimId){
        return this.r({
            method: 'GET',
            url: `/claim/${claimId}/missing-documents`,
        });
        }

        return this.r({
            method: 'GET',
            url: `/files/missing-documents`,
        });
    };

    downLoadFile = (filePath: string) => {
        return this.r({
            method: 'GET',
            url: filePath,
            responseType: 'arraybuffer'
        });
    };
}

export default function claim(request: ReqTypeT) {
    return new ClaimApi(request);
}
