import React from 'react';

import { FeedMessageLine } from "../../../DataStructure/Components";

type MainPropsT = {
    t: (data: string, params?: any) => string
}

const MCNoReport: React.FC<MainPropsT> = ({ t }) => {
    return (
        <>
            <FeedMessageLine
                data={t('chatMessages.repairBudgetNoSended')}
                float={'right'}
            />
        </>
    );
}
export default MCNoReport;