import React from 'react';

import { FeedMessageLine } from "../../../DataStructure/Components";
import style from './MCRefundProcess.module.scss';

type MainPropsT = {
    t: (data: string, params?: any) => string,
    bankAccount: any
}

const MCRefundProcess: React.FC<MainPropsT> = ({ bankAccount, t }) => {

    return (
        <div className={style.root}>
            <FeedMessageLine
                data={[
                    (bankAccount && bankAccount?.accountNumber) ?
                    [
                        t('chatMessages.moneyCompensationData'),
                        `${t('chatMessages.moneyCompensationDataAccount')}<br /><br />${t('labels.fullName')}: <strong>${bankAccount?.holder}</strong><br />${t('labels.contactData')}: <strong>${bankAccount?.address}</strong><br />${t('labels.accountNumber')}: <strong class="account">${bankAccount?.accountNumber}</strong>`
                    ]: t('chatMessages.moneyCompensationData')
                ]}
                float={'right'}
            />
        </div>
    );
}
export default MCRefundProcess;