import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { calculateDateDifference, getAgeName } from "Helpers/date";
import StatusBubble from "Components/StatusBubble/StatusBubble";
import LoadingCss from "Components/LoadingCss/LoadingCss";
import { useMobile } from 'Helpers/responsiveContainers';
import type { ClaimT } from 'api/modules/claim';
import useKeyImages from "Hooks/useKeyImages";
import { photoMainKey } from "Helpers/claim";
import styles from './CaseList.module.scss';
import { prepareUrl } from 'Helpers/claim';

interface IClaimList {
	data: ClaimT[];
	className?: string;
	onSelect: (id: string) => void;
}

interface ICaseItem {
	data: ClaimT;
	onClick: () => void;
	img: string|null
}

export function CaseItem({ data, onClick, img }: ICaseItem) {
	const { t } = useTranslation();
	const isMobile = useMobile();
    const diffData = calculateDateDifference(data?.verdict?.purchaseDate, data?.verdict?.createdAt);

	return (
		<div className={styles.caseItem} onClick={onClick}>
			<div className={styles.metaRow}>
				<div className={styles.metaItem}>
					<span className={styles.metaTitle}>{t('claimList.referenceLabel')}</span>
					<span className={styles.metaValue}>{data.referenceNumber || '-'}</span>
				</div>

				<div className={styles.metaItem}>
					<span className={styles.metaTitle}>{t('claimList.caseNumberLabel')}</span>
					<span className={styles.metaValue}>{data.caseNumber}</span>
				</div>
			</div>

			{img ?
				<div
					className={styles.image}
					style={{ backgroundImage: `url(${img})` }}
				/>:
				<div className={cx(styles.image, styles.imageLoading)}><LoadingCss /></div>
			}

			<div style={{ textAlign: 'center', marginBottom: 20, position: 'relative' }}>
				<StatusBubble
					status={data.status}
					verdict={data.verdict}
					id={data.id}
					shipments={data?.shipments}
					data={{
						voucher: data.verdict?.resolution?.voucher || 0,
						residualValue: data.verdict?.resolution?.residualValue || 0,
						templateValue: data.verdict?.resolution?.refund || 0,
						baggageAge: getAgeName((diffData.years * 12) + diffData.months, t),
						currencyIsoCode: data?.currencyIsoCode,
						airlineName: data?.airlineName,
						urlForm: prepareUrl(data, isMobile),
						urlGoogleReview: data?.case?.review?.googleMapsUrl,
						urlTrustPilot: data?.case?.review?.trustPilotUrl,
						pickupData: data?.resolutions && Array.isArray(data.resolutions) ? ` <strong>${data.resolutions.find(r => r.status === 'success')?.resolutionData?.date}</strong>` || '' : '',
					  }} 
				/>
			</div>
		</div>
	);
}

export default function ClaimList({ onSelect, ...props }: IClaimList) {

	const photoKeys = useKeyImages(props.data);

	return (
		<div className={styles.claimList}>
			<ul className={cx(styles.root, props.className)}>
				{props.data.map((claim) => (
					<li key={claim.id} className={styles.listItem}>
						<CaseItem
							data={claim}
							onClick={() => onSelect(claim.id)}
							img={photoKeys.find(pk => pk.key === photoMainKey(claim))?.url || null}
						/>
					</li>
				))}
			</ul>
		</div>
	);
}
