import React, { useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import cx from 'classnames';

import shopStyle from '../../Shop/AssessmentVerdictAccessShop/AssessmentVerdictAccessShop.module.scss';
import { AppStateT, ClaimChatStateT, ClaimT, UserStateT } from "../../../DataStructure/interfaces";
import { getClosestWorkingDay } from "../../../DataStructure/helpers";
import RepairPickUpForm from "./RepairPickUpForm/RepairPickUpForm";
import { SvgRepair } from "../../../DataStructure/SvgData";
import { Modal } from "../../../DataStructure/Components";
import style from './PickupDataEntry.module.scss';
interface IUserSelector {
    user: UserStateT['data'];
}

type MainPropsT = {
    t: (data: string, params?: any) => string,
    claim: ClaimT,
    disabled?: boolean
}

// [SOLUTION_PICKUP_PENDING]
const PickupDataEntry: React.FC<MainPropsT> = ({ t, claim, disabled }) => {

    const [showModal, setShowModal] = useState(false);
    const { user } = useSelector<AppStateT, IUserSelector>(({ user }) => ({ user: user.data }));
    const { repair } = useSelector<AppStateT, ClaimChatStateT>(({ claimChat }) => claimChat);
    const repairLocation = repair.userLocation;

    const actionOpenPickup = useCallback(() => {
        if (disabled) return;
        setShowModal(true);
    }, [disabled]);

    return (
        <div className={style.root}>
            <br />
            <div className={cx(shopStyle.root, shopStyle.repair, { [shopStyle.disabled]: disabled })}>
                <div className={shopStyle.shopLayer}>
                    <div className={shopStyle.shopHeader}>{t('chatMessages.afterVerdictPickup1')}</div>
                    <div onClick={() => actionOpenPickup()} className={shopStyle.catalogItem}>
                        <div className={shopStyle.ico}>
                            <SvgRepair />
                        </div>
                        <div className={shopStyle.textBody}>
                            <div className={shopStyle.catalog}>
                                <span className={shopStyle.shopName}>
                                    {t('resolutionOptions.repairTitle')}
                                </span>
                                <div>{t('resolutionOptions.repairDescription')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal &&
                <Modal
                    open
                    onClose={() => setShowModal(false)}
                    title={t('resolutionModalTitles.repair')}
                >
                    <Modal.Header></Modal.Header>
                    <RepairPickUpForm
                        claimId={claim.id}
                        close={() => setShowModal(false)}
                        error={""}
                        initialValues={{
                            name: (user?.firstName || '') + ' ' + (user?.lastName || ''),
                            location: repairLocation as LocationT,
                            date: getClosestWorkingDay(),
                        }}
                        loading={false}
                        openSaveASDraftModal={() => null}
                        isBlocked={!!claim?.isBlocked}
                    />
                </Modal>
            }
        </div>
    );
}
export default PickupDataEntry;