import { getExamplesMissingDocuments as getExamplesMissingDocumentsMain, ReportSendT as ReportSendMainT, sendReportProblem as sendReportProblemMain } from "services/api";
import runApiMain, { ResultApiTypeT as ResultApiTypeMainT, sendDataFiles as sendDataFilesMain } from "api/base/runApi";
import { getUrlShopToken as getUrlShopTokenMain } from "services/shop";
import { ApiSendMethods as ApiSendMethodsMain } from "api/base/runApi";

export const getUrlShopToken = getUrlShopTokenMain;

export const runApi = runApiMain;
export type ResultApiTypeT = ResultApiTypeMainT;
export const sendDataFiles = sendDataFilesMain;
export const getExamplesMissingDocuments = getExamplesMissingDocumentsMain;
export type ReportSendT = ReportSendMainT;
export const sendReportProblem = sendReportProblemMain;
export const ApiSendMethods = ApiSendMethodsMain;