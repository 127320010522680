const Regs = {
    letters: /^[\p{L}\s]*$/u, // letters and space
    surname: /^[-\p{L}\s]*$/u, // surname
    numbers: /^[0-9\s-]+$/, // numbers only
    lettersNumbers: /^[\p{L}0-9\s,.'-]*$/u, // numbers and letters and spaces
    street: /^[\p{L}0-9\s,.'-/]*$/u, 
    phone: /^\+?([0-9]{1,4})\)?[-. ]?([0-9]{3,6})[-. ]?([0-9]{4,5})$/,
    fileReference: /^[A-Za-z]{5}[0-9]{5}$/,
    iban: /^[a-zA-Z]{2}[a-zA-Z0-9\s]{8,}$/, 
    city: /^[\p{L}0-9\s,.'-/]*$/u, 
    swiftBic: /^[a-zA-Z0-9]{6,}$/,
    creditCardNumber: /^[0-9]{4}(?:[0-9]{9}|-[0-9]{4}-[0-9]{4}-[0-9]{4})$/,
    creditCardExp: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
    creditCardCvv: /^[0-9]{3,4}$/,
    referenceNumber: /^[A-Za-z]{5}[0-9]{5}$/,
    nipBrazilCpf: /^(?:\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/,
    //email: /^(?!.*[.-]{2})[a-zA-Z\d._]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
    // eslint-disable-next-line no-useless-escape
    email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
    claimId: /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
}

export default Regs;
