import { toast, TypeOptions } from 'react-toastify';
import parse from 'html-react-parser';

import IcoNotify from 'Components/IcoNotify/IcoNotify';

type NotifyT = {
    message: string,
    type?: TypeOptions,
    dismiss?: number
}

export const runNotify = ({ message, type = 'info', dismiss = 2500 }: NotifyT) => {
    toast(<span>{parse(String(message))}</span>, {
        position: "top-right",
        autoClose: dismiss || 2500,
        icon: (() => <IcoNotify type={type} />) as any
    });
}
