import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-tooltip/dist/react-tooltip.css';
import {
    Login,
    Registration,
    Confirmation,
    CreateClaimPage,
    FilterPage,
    ClaimList,
    FeedPage,
    StoreDesktop,
    AboutUs,
    PaymentResult
} from 'Pages';
import { NotMobileView, useMobile } from 'Helpers/responsiveContainers';
import { PrivateRoute, Loader, WithGoogleApi } from 'Components';
import { AccountSettingsSideBar } from 'PageComponents/Chat';
import { HeaderDesktop } from 'PageComponents/ClaimList';

import { LuggageDetails, LuggageStoreContainer, ShoppingCartContainer } from "PageComponents/LuggageStore";
import ModalWaitRedirectShop from "Components/ModalWaitRedirectShop/ModalWaitRedirectShop";
import { setUserLanguage, type StateT as UserStateT } from 'Store/modules/user';
import BlockServerError from 'PageComponents/BlockServerError/BlockServerError';
import ModalShopSuccess from "Components/ModalShopSuccess/ModalShopSuccess";
import { SocketProvider } from 'PageComponents/SocketContext/SocketContext';
import { StateT as StateLang, changeLanguage } from 'Store/modules/i18n';
import { StateT, setNotify, setShowContact } from 'Store/modules/page';
import ClaimMessage from "PageComponents/ClaimMessage/ClaimMessage";
import ArticleModal from 'Components/ArticleModal/ArticleModal';
import runApi, { ApiSendMethods } from "api/base/runApi";
import { setLoadingClaims } from "Store/modules/claim";
import { SupportedLanguageT } from "api/modules/i18n";
import { getSystemLanguage } from "Helpers/browser";
import { runNotify } from 'Helpers/notifications';
import OutParams from 'Pages/OutParams/OutParams';
import Contact from "Components/Contact/Contact";
import Tooltip from 'Components/Tooltip/Tooltip';
import { preferedorderLanguages } from 'Config';
import type { AppStateT } from 'Store/modules';
import Wrapper from './Wrapper/Wrapper';

let timeout: any = null;
// let cookieReadInterval: any = null;
const waitToCheckErrorServer = 2000;

export default function AppRouter({ tReady }: { tReady: boolean }) {
    const { initialized, data: userData } = useSelector<AppStateT, UserStateT>(({ user }) => user);
    const languages = useSelector<AppStateT, SupportedLanguageT[]>(({ i18n }) => i18n.supportedLanguages?.data || []);
    const i18Data: StateLang = useSelector<AppStateT, StateLang>(({ i18n }) => i18n);
    const { notify, blockServerError, messageCase, showContact, showModalRedirectShop, showModalStoreSuccess } = useSelector<AppStateT, StateT|null>((state) => state.page);
    const [canCheckErrorServer, setCanCheckErrorServer] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMobile = useMobile();
    const [counterChange, setCounterChange] = useState(0);

    useEffect(() => {
        document.documentElement.setAttribute('translate', 'no');
    }, []);

    useEffect(() => {
        dispatch(setLoadingClaims(true));
    }, [dispatch]);

    useEffect(() => {
        const langLS = localStorage.getItem('language');
        if (langLS && !userData?.language) {
            dispatch(changeLanguage(langLS));
        } else if (!userData?.language && i18Data.supportedLanguages?.data && i18Data.supportedLanguages.data.length > 0) {
            const tryDetectLanguage = i18Data.supportedLanguages.data.find(l => l.value === getSystemLanguage().codeLowerCase);
            if (tryDetectLanguage) {
                dispatch(changeLanguage(tryDetectLanguage.value));
            } else {
                for (let i = 0; i < preferedorderLanguages.length; i++) {
                    if (i18Data.supportedLanguages.data.some(lang => lang.value === preferedorderLanguages[i])) {
                        dispatch(changeLanguage(preferedorderLanguages[i]));
                        break;
                    }
                }
            }
        }
    }, [i18Data.supportedLanguages.data, userData?.language, dispatch]);

    // read data from cookie system CCM19
    // useEffect(() => {

    //     const readCookie = () => {
    //         const dataCookie = localStorage.getItem('ccm_consent');
    //         let acceptCoookies = true;
    //         let showCookies = false;
    //         if (dataCookie){
    //             const dataCookieParse = JSON.parse(dataCookie);
    //             acceptCoookies = dataCookieParse?.['/']?.clickedButton === 'acceptAll';
    //         }
    //         showCookies = !!document.querySelector("#ccm-widget.ccm-show");
    //         if (acceptCoookies !== acceptCookies && showCookies !== cookiesShow){
    //             dispatch(setCookiesAccept({ acceptCookies, showCookies }));
    //         }
    //     }

    //     cookieReadInterval = setInterval(() => {
    //         readCookie();
    //     }, 1000);

    //     return () => {
    //         clearInterval(cookieReadInterval);
    //     }

    // }, [cookiesShow, acceptCookies, dispatch]);

    useEffect(() => {
        const langCode = window.location.pathname.substring(1);
        if (langCode && (langCode.length === 2 || langCode.length === 5) && counterChange === 1 && userData?.id && languages.length > 0) {
            setCounterChange(old => old + 1);
            const lang = languages.find(l => l.value === langCode);
            if (lang) {
                dispatch(changeLanguage(lang.value));
                dispatch(setUserLanguage(lang.value));
                const personalInfo = {
                    language: lang.value
                }
                runApi('user', personalInfo, null, ApiSendMethods.patch);
            }
        }
    }, [counterChange, userData?.id, dispatch, languages]);

    useEffect(() => {
        const langCode = window.location.pathname.substring(1);
        if (langCode && (langCode.length === 2 || langCode.length === 5) && counterChange === 0 && languages.length > 0) {
            const lang = languages.find(l => l.value === langCode);
            if (lang) {
                localStorage.setItem('languageTrySet', langCode);
                dispatch(changeLanguage(lang.value));
                setCounterChange(old => old + 1);
            }
        }
    }, [languages, counterChange, dispatch]);

    const onFocus = useCallback(() => {
        if (document.hidden) return;
        setCanCheckErrorServer(false);
        timeout = setTimeout(() => {
            setCanCheckErrorServer(true);
        }, waitToCheckErrorServer);
    }, []);

    useEffect(() => {
        window.addEventListener("visibilitychange", onFocus, false);
        return () => {
            clearTimeout(timeout);
        }
    }, [onFocus]);

    useEffect(() => {
        if (userData?.id) {
            localStorage.removeItem('languages');
        }
    }, [userData?.id]);

    useEffect(() => {
        if (notify) {
            runNotify({ message: t(notify.message), type: notify.type || 'default' });
            dispatch(setNotify(null));
        }
    }, [notify, dispatch, t]);

    const getToken = useCallback(() => {
        const userToken = localStorage.getItem('token');
        return userToken || '';
    }, []);

    if (!initialized || !tReady) {
        return <Loader />;
    }

    return (
        <Wrapper wrappingLayer={isMobile}>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Registration />} />
                <Route path="/confirm" element={<Confirmation />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/:id/payment/:orderId/:paymentStatus/:paymentCode" element={<PaymentResult />} />
                <Route path="/p/:type/:value" element={<OutParams />} />
                <Route
                    path="*"
                    element={
                        <PrivateRoute>
                            <SocketProvider jwtToken={getToken()}>
                                <NotMobileView>
                                    <>
                                        <HeaderDesktop />
                                        <AccountSettingsSideBar />
                                    </>
                                </NotMobileView>
                                <WithGoogleApi>
                                    <Routes>
                                        <Route path="/claim/:id" element={<ClaimList />} />
                                        <Route path="/create" element={<CreateClaimPage />} />
                                        <Route path="/filters" element={<FilterPage />} />
                                        <Route path="/claim/:id/:action?" element={<FeedPage />} />
                                        <Route path="/createDesktop" element={<ClaimList />} />
                                        <Route path="/archive/:id?/:action?" element={<ClaimList archive />} />
                                        <Route path="/:id?/:action?" element={<ClaimList />} />
                                        <Route path="/store/:claimId/*" element={<StoreDesktop />}>
                                            <Route path="shopping-cart" element={<ShoppingCartContainer />} />
                                            <Route path="luggage-details/:id/:productSimpleId?" element={<LuggageDetails />} />
                                            <Route index element={<LuggageStoreContainer />} />
                                        </Route>
                                    </Routes>
                                </WithGoogleApi>
                                {(messageCase && !isMobile) && <ClaimMessage />}
                                {(showContact && !isMobile) && <Contact actionClose={() => dispatch(setShowContact(null))} modal />}
                            </SocketProvider>
                        </PrivateRoute>
                    }
                />
            </Routes>
            <Tooltip />
            <ArticleModal />
            <ToastContainer
                hideProgressBar
            />
            {(blockServerError && canCheckErrorServer) &&
                <BlockServerError type={blockServerError} />
            }
            {showModalRedirectShop &&
                <ModalWaitRedirectShop />
            }
            {showModalStoreSuccess &&
                <ModalShopSuccess />
            }
        </Wrapper>
    );
}
