import { CountdownCircleTimer } from "react-countdown-circle-timer";
import React, { useCallback, useMemo, useState } from 'react';

import { formatSecondsToTimeString, getSecondsDifference } from "../../DataStructure/helpers";
import { FeedMessageLine } from "../../DataStructure/Components";
import { LoadingCss } from "../../DataStructure/Components";
import style from './WaitingProgress.module.scss';

type MainPropsT = {
    label?: string,
    untilDate?: string,
    createdAt?: string
}

const WaitingProgress: React.FC<MainPropsT> = ({ label, untilDate, createdAt }) => {

    const [hide, setHide] = useState(false);
    const leftSeconds = useMemo(() => untilDate && createdAt ? getSecondsDifference(createdAt, untilDate) : -1, [createdAt, untilDate]);

    const onCounterEnd = useCallback(() => {
        setHide(true);
    }, []);

    if (hide) return null;

    return (
        <div className={style.root}>
            {leftSeconds > 0 ?
                <CountdownCircleTimer
                    isPlaying
                    // initialRemainingTime={initialVerificationCodeCounter}
                    duration={leftSeconds}
                    colors={'#2fc3dd'}
                    size={leftSeconds > 3600 ? 90 : 50}
                    strokeWidth={leftSeconds > 3600 ? 5 : 3}
                    onComplete={onCounterEnd}
                >
                    {({ remainingTime }) => formatSecondsToTimeString(remainingTime) }
                </CountdownCircleTimer>:
                <FeedMessageLine
                    data={<div className={style.loading}>{label && <p>{label}</p>}<LoadingCss width={50} /></div>}
                    showAvatar={false}
                    transparent
                    hideAvatar
                />
            }
        </div>
    );
}
export default WaitingProgress;