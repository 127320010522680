import { ClaimCreateT, RequiredDocumentT, StepT } from "PageComponents/CreateClaim/CreateClaim/CreateForm/CreateForm";
import { IImage } from "PageComponents/Chat/ReportUploadBox/ReportUploadBox";
import runApi, { ApiSendMethods, ResultApiTypeT } from "api/base/runApi";
import { updateClaim } from "Store/modules/claimChat/data";
import { DocumentTypeEnum } from "Store/Enums/newCalim";
import { IReadDocumentT } from "Store/Types/claim";
import { PageConfigT } from "Store/modules/page";
import { isFirefox } from "Helpers/browser";
import { ClaimT } from "api/modules/claim";
import { getImageOcrData } from "./api";

export const sortMissedDocument = (a: RequiredDocumentT, b: RequiredDocumentT) => {
    const _isFirefox = isFirefox();
    if (_isFirefox){
        return a.Name.indexOf('DPR Copy') >= 0 ? 1 : 0;
    }
    return a.Name.indexOf('DPR Copy') >= 0 ? -1  : 0;
}

export const getClaimData = (claimId: string, dispatch?: (para: any) => void, callBack?: (result: boolean, data?: ClaimT|null) => void) => {

    runApi(`claim/${claimId}`, {}, (r: ResultApiTypeT) => {
        if (r.result && r.data){
            dispatch?.(updateClaim(r.data));
            return callBack?.(true, r.data);
        }
        callBack?.(false);
    }, ApiSendMethods.get);

}

export const checkOcrSteps = [ 'Baggage Tag copy', 'DPR copy' ];

export const getOcrData = (photos: ClaimCreateT['photos'], step: StepT, setState: (data: any) => void, configClaimMap: PageConfigT['claim']['reportTagsMapReversed']) => {

    const getPhotoKey = (key: string): { key: string, array?: boolean } => {
        switch(key){
            case DocumentTypeEnum.BAGGAGE_TAG_COPY: return { key: 'missed_Tag'};
            default: return { key: 'missed_Dpr' , array: true };
        }
    }

    const tag = configClaimMap[step.type];
    const key = getPhotoKey(tag);
    const data: any = photos[key.key];
    const dataSend: IImage[] = Array.isArray(data) ? data : [data];

    const readOcrData = async (files: { filename: string }[], images: File[], missingDocumentName: string): Promise<IReadDocumentT|null> => {
        return await getImageOcrData({ files, images, missingDocumentName });
    }

    setState({
        type: 'SET_READ_OCR',
        data: true
    });


    if (dataSend.length === 0){
        setState({
            type: 'SET_READ_OCR',
            data: false
        });
        return;
    }

    /*
    * Reading OCR Data
    **/
    readOcrData(dataSend.map(d => {
        return {
            filename: d.file.name
        }
    }), dataSend.map(f => f.file), tag).then(data => {

        if (data && data.readResult?.referenceNumber?.value){
            setState({
                type: 'SET_OCR_DATA',
                data,
                referenceNumber: data.readResult.referenceNumber.value
            });
        } else {
            setState({
                type: 'SET_READ_OCR',
                data: false
            });
        }

    }).catch(err => {
        console.log('err read data ocr: ', err);
    });

}