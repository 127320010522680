import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { ReactComponent as UploadIcoPdf } from 'Assets/upload-pdf.svg';
import style from './SparePartsResolution.module.scss';

import ChatMessageGroup, { IMessage } from 'Components/ChatMessageGroup/ChatMessageGroup';
import ClaimBlockedOnChat from "Components/ClaimBlockedOnChat/ClaimBlockedOnChat";
import ImageDecrypt from 'Components/ImageDecrypt/ImageDecrypt';
import { ClaimHistoryT } from 'api/modules/case-resolution';
import { useMobile } from 'Helpers/responsiveContainers';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import { ClaimT, VerdictT } from 'api/modules/claim';
import { runNotify } from 'Helpers/notifications';
import { PageConfigT } from 'Store/modules/page';
import { getUrlOfImage } from 'services/api';
import { prepareUrl } from 'Helpers/claim';
import { AppStateT } from 'Store/modules';


type PropsMainT = {
    claim: ClaimT,
    statusInfo: ClaimHistoryT,
}

const SparePartsResolution: React.FC<PropsMainT> = ({ claim, statusInfo }) => {
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const { t } = useTranslation();
    const isMobile = useMobile();

    const [assessmentResultData, setAssessmentResultData] = useState<VerdictT | null>(null);
    const formatPrice = useFormattedPrice(claim.currencyIsoCode, pageConfig.noFractionCurrencies);

    const downloadInfo = useCallback((documentKey: string) => {
        getUrlOfImage(documentKey, (result: boolean, url: string | undefined) => {
            if (result && url) {
                window.open(url);
            } else {
                runNotify({ message: 'Error while trying to download the file', type: 'error' });
            }
        });
    }, []);

    useEffect(() => {

        if (claim?.verdict && (claim.verdict.resolution.voucher || claim.verdict.value)) {

            const verdict = claim?.verdict;
            if (verdict?.resolution?.sparePartsPaxValidation){
                setAssessmentResultData(verdict);
            }
            const _m: IMessage[] = [
                { render: () => (
                    <>
                        {assessmentResultData && assessmentResultData.resolution.responseData ? (
                            <div className={style.dataExist}>
                                {assessmentResultData.resolution.responseData.sparePartsPhotos && assessmentResultData.resolution.responseData.sparePartsPhotos.length > 0 ? (
                                    <div className={style.photos}>
                                        <label>{t('chatMessages.sparePartsSendedPhoto')}</label>
                                        <div className={style.photosLayer}>
                                            {assessmentResultData.resolution.responseData.sparePartsPhotos.map(photoFromAssessment => (
                                                <div className={style.photo} key={`ph-${photoFromAssessment.original.key}`}>
                                                    {photoFromAssessment.name.endsWith('.pdf') ? (
                                                        <div className={style.pdfIconContainer} onClick={() => downloadInfo(photoFromAssessment.name)}>
                                                            <UploadIcoPdf className={style.pdfIcon} />
                                                        </div>
                                                    ) : (
                                                        <ImageDecrypt imageKey={photoFromAssessment.thumbnail.key} />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className={style.resign}>{t('chatMessages.sparePartsGiveup')}</div>
                                )}
                            </div>
                        ) : null}
                    </>
                )},
                { render: () => (
                    <>

                        {parse(t('chatMessages.moneyCompensationResultLabel', {
                            price: `<span class="price">${formatPrice(claim.verdict.resolution.voucher || claim.verdict.value)} ${claim.currencyIsoCode}</span>`,
                            url: `<a href="${prepareUrl(claim, isMobile)}" class="linkChat">${t('common.link')}</a>` 
                        }))}
                        </>
                )}
            ];
    
            setMessages(_m);
        } else {
            setMessages([])
        }

    }, [t, claim.currencyIsoCode, isMobile, claim, formatPrice, assessmentResultData, downloadInfo]);

    if (messages.length === 0){
        return <></>;
    }

    if (!claim?.verdict?.resolution?.moneyCompensationUrl){
        return <ClaimBlockedOnChat claim={claim} reason={t('chatMessages.sparePartsNoUrl')} />
    }

    return (
        <ChatMessageGroup
            data={messages}
            direction={'incoming'}
            statusInfo={statusInfo}
            as="MessageGroup"
            avatar='bot'
        >
        </ChatMessageGroup>
    );
}
export default SparePartsResolution;