import cx from 'classnames';
import React from 'react';

import { ReactComponent as OkIcon } from 'Assets/okey.svg';

import styles from './Checkbox.module.scss';

export type MainPropsCheckboxT = {
    checked: boolean;
    name: string,
    onChange?: (param: boolean, event: any) => void,
    className?: string,
    id?: string,
    disabled?: boolean
}

const Checkbox: React.FC<MainPropsCheckboxT> = ({ checked, onChange, className = '', id, disabled }) => {

    return (
        <div 
            className={cx(styles.input, className, {
                [styles.isChecked]: checked,
                [styles.disabled]: disabled,
            })}
            onClick={!disabled ? (event) => onChange?.(!checked, event) : undefined}
            id={id}
        >
            <div className={styles.icon}>
                <OkIcon />
            </div>
        </div>
    )
}

export default React.memo(Checkbox);