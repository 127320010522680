import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateT } from 'Store/modules';
import { useMobile } from 'Helpers/responsiveContainers';
import { helpCenterAvailableLanguages } from 'Helpers/i18';
import { actionShowCookies } from 'Helpers/browser';
import useTranslate from 'Hooks/useTranslate';
import DrawerMenu from '../DrawerMenu/DrawerMenu'; 
import styles from './Footer.module.scss';
import cx from 'classnames';

import { ReactComponent as CookieIcon } from 'Assets/cookies-settings-icon.svg';

export type UrlActionTypeT = 'login'|'about'|'terms'|'privacy'|'privacyPolicy'|'language'|'help'|'personalInfo'|'logOut';
const FOOTER_LINKS_FIRST_ROW = [
    { name: 'footer.signIn', path: '/login' },
    { name: 'footer.aboutUs', path: '/about' },
    { name: 'footer.help', path: '/help' },
    { name: 'footer.language', path: '/lang' },
    { name: 'footer.privacyPolicy', path: '/privacyPolicy' },
];

const FOOTER_LINKS_SECOND_ROW = [
    { name: 'footer.privacySettings', path: '/privacy' },
    { name: 'footer.termsAndConditions', path: '/terms' },
];

export default function Footer() {
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
    const isMobile = useMobile();
    const [open, setOpen] = useState(false);
    const t = useTranslate();

    const toggleDrawer = (newOpen: boolean) => setOpen(newOpen);

    const footerLinksFirstRow = useMemo(() => 
        FOOTER_LINKS_FIRST_ROW.filter(link => link.name === 'footer.help' ? helpCenterAvailableLanguages.includes(actualLanguage) : true), 
        [actualLanguage]);

    const footerLinksSecondRow = useMemo(() => 
        isMobile ? FOOTER_LINKS_SECOND_ROW : FOOTER_LINKS_SECOND_ROW.filter(link => link.name !== 'footer.privacySettings'), 
        [isMobile]);

    return (
        <>
            <footer className={styles.rootDesktop}>
                <div className={styles.iconContainer}>
                    <div
                        className={cx(styles.icon, styles.iconCookie)}
                        onClick={actionShowCookies}
                        title={t('common.cookiesInfo')}
                    > 
                        {!isMobile&&<CookieIcon />}
                    </div>
                    <DrawerMenu
                        open={open}
                        toggleDrawer={toggleDrawer}
                        footerLinksFirstRow={footerLinksFirstRow}
                        footerLinksSecondRow={footerLinksSecondRow} 
                    />
                </div>
        
            </footer>
 
        </>
    );
}
