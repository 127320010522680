import React from 'react';

import style from './LoadingCss.module.scss';

export type MainPropsLoadingCssT = {
    version?: 1|2|3,
    width?: number,
    color?: string,
    borderWidth?: number
}

const LoadingCss: React.FC<MainPropsLoadingCssT> = ({ version = 1, width = 20, color = '#2fc3dd', borderWidth = 2 }) => {
    return (
        <div
            className={version === 1 ? style.loader : style.loader2}
            style={{
                width,
                height: width,
                borderColor: color,
                borderWidth
            }}
         />
    );
}
export default LoadingCss;