import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import cx from 'classnames';

import { ReactComponent as CalendarIcon } from 'Assets/calendar.svg';

import { AirlineT, LandingStationT, StateLocalT, defaultCountry } from '../../CreateForm';
import DropDownSearch from 'Components/DropDownSearch/DropDownSearch';
import { getPropablyCountryNameByCode } from "Helpers/strings";
import type { StateT as UserStateT } from 'Store/modules/user';
import { getSystemLanguage, isFirefox } from 'Helpers/browser';
import { formatDateShortOnly, getDaysAgo } from 'Helpers/date';
import { removeDuplicatedNamesAirlines } from 'Helpers/claim';
import { countriesCodes } from 'Helpers/languageCode';
import { searchAirlineById } from 'Helpers/claim';
import { defaultLanguage } from "Helpers/i18";
import { countrys } from 'Helpers/countrys';
import style from './Airline.module.scss';
import { useSelector } from 'react-redux';
import { AppStateT } from 'Store/modules';
import { Loader } from 'Components';

type MainProps = {
    state: StateLocalT,
    validStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    updateState: (dataType: string, data: any) => void,
    mobile: boolean,
    actionSetEditIndex: (data: number|null) => void
}

type AirlineLocalT = {
    value: string,
    text: string,
}

const Airline: React.FC<MainProps> = ({ state, validStep, updateClaim, updateState, mobile, actionSetEditIndex }) => {

    const language = useSelector<AppStateT, string>((state) => {
        const languageCode = countriesCodes.find((code) => state.i18n.language === code.countryCode)
        return languageCode?.localeCode || defaultLanguage
    });
    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const [airlines] = useState(state.airlines.filter(airline => airline && airline.Id && airline.Airline__c && airline.Name).map((airline: AirlineT) => {
        return { value: airline.Id, text: `[${airline.Name}] ${airline.Airline__c}` };
    }));
    const [landingStations] = useState(state.landingStations.filter(landing => landing && landing.name && landing.city).map((landing: LandingStationT) => {
        return { value: landing.id, text: '[' + landing.name + '] ' + landing.city, city: landing.city };
    }));
    const { t } = useTranslation();
    const [airlineId] = useState(state.claimData.airlineId);
    const [airlineDateLanding] = useState(state.claimData.airlineDateLanding);
    const [originEditIndex] = useState(state.editStepIndex);
    const [clientLocationAirlineCountryCode] = useState(state.claimData.clientLocationAirlineCountry.code);
    const [showData, setShowData] = useState<'countrys'|'calendar'|'landingAirport'|'landingAirline'|null>(null);

    useEffect(() => {
        valid(state.claimData.airlineDateLanding, state.claimData.airlineId, state.claimData.clientLocationAirlineCountry.code, state.claimData.landingStationSelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.claimData.airlineDateLanding, state.claimData.airlineId, state.claimData.clientLocationAirlineCountry.code, state.claimData.landingStationSelected]);

    useEffect(() => {
        const _countryName = user.location.country || countrys.find(co => co.value.toLowerCase() === user?.location?.countryCode.toLowerCase())?.text;
        if (user?.location?.countryCode && _countryName){
            updateClaim('clientLocationAirlineCountry', {
                code: user.location.countryCode,
                name: _countryName
            });
        } else {
            const c = getSystemLanguage().codeUpperCase;
            const country = countrys.find(co => co.value.toLowerCase() === c.toLowerCase());
            if (country){
                updateClaim('clientLocationAirlineCountry', {
                    code: user?.location?.countryCode || country.value,
                    name: _countryName || getPropablyCountryNameByCode(country.value || defaultCountry)
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, countrys]);

    const valid = (airlineDateLanding: Date | null, airlineName: string, countryCode: string, landS: LandingStationT | null) => {
        if (airlineDateLanding && airlineName.length >= 3 && countryCode && landS){
            validStep(true);
        } else {
            validStep(false);
        }
    }

    const checkOriginEditIndex = (date: Date|null, paramAirlineId : string, countryCode: string) => {
        if (originEditIndex){
            if (airlineId === paramAirlineId && date?.toISOString() === airlineDateLanding?.toISOString() && clientLocationAirlineCountryCode === countryCode){
                actionSetEditIndex(originEditIndex);
            } else {
                actionSetEditIndex(null);
            }
        }
    }

    const onChangeCalendar = (date: any) => {
        updateClaim('airlineDateLanding', date);
        setShowData(null);
        valid(date, state.claimData.airlineName, state.claimData.clientLocationAirlineCountry.code, state.claimData.landingStationSelected);
        checkOriginEditIndex(date, state.claimData.airlineId, state.claimData.clientLocationAirlineCountry.code);
    }

    const actionChangeCountry = (data: { text: string, value: string }) => {
        updateClaim('clientLocationAirlineCountry', {
            code: data.value,
            name: data.text
        });
        checkOriginEditIndex(state.claimData.airlineDateLanding, state.claimData.airlineId, data.value);
    }

    const actionChangeAirline = (data: AirlineLocalT) => {
        updateState('claimData', {
            ...state.claimData,
            airlineName: data.text,
            airlineId: data.value
        });
        checkOriginEditIndex(state.claimData.airlineDateLanding, data.value, state.claimData.clientLocationAirlineCountry.code);
    }

    const actionShowCalendar = () => {
        // actionCalendarClick();
        setShowData('calendar');
    }

    const actionChangeLandingStation = (data: any) => {
        const result = state.landingStations.find(l => l.id === data.value);
        updateClaim('landingStationSelected', result || null);
    }

    return (
        <div className={style.root}>
            {showData === 'calendar' && <div className={style.maskDatePicker} onClick={() => setShowData(null)} />}

            {!showData &&
                <div className={cx(style.header, { [style.selected]: !!state.claimData.airlineId })}>{t('createClaim.airline')}</div>
            }

            {(showData === 'landingAirline' || !showData) &&
                <DropDownSearch
                    label={t('common.requiredField')}
                    data={[...removeDuplicatedNamesAirlines(airlines).sort((a,b) => a.text < b.text ? (isFirefox() ? 0 : -1) : (isFirefox() ? 1 : 0))]}
                    onChange={(data) => actionChangeAirline(data)}
                    value={searchAirlineById(state.airlines, state.claimData.airlineId)}
                    className={style.inputLayer}
                    t={t}
                    search
                    onExpand={expand => setShowData(expand ? 'landingAirline' : null)}
                    id={"search_new_claim_airline"}
                />
            }

            {(showData === 'landingAirport' || !showData) &&
                <>
                    <div className={cx(style.header, style.landingStations, { [style.selected]: !!state.claimData.landingStationSelected })}>{t('createClaim.landingStationHeader')}</div>

                    <DropDownSearch
                        data={landingStations.sort((a,b) => a.city < b.city ? (isFirefox() ? 0 : -1) : (isFirefox() ? 1 : 0))}
                        label={t('common.requiredField')}
                        value={state.claimData.landingStationSelected ? (landingStations.find(ls => ls.value === state.claimData.landingStationSelected?.id)?.text || '') : ''}
                        onChange={(data) => actionChangeLandingStation(data)}
                        search
                        t={t}
                        onExpand={expand => setShowData(expand ? 'landingAirport' : null)}
                        id={"search_new_claim_landing_station"}
                    />
                </>
            }

            {(showData === 'calendar' || !showData) &&
                <>
                    <div className={cx(style.header, { [style.selected]: !!state.claimData.airlineDateLanding })}>{t('createClaim.airlineDate')}</div>
                    {(showData === null || (showData === 'calendar' && !mobile)) &&
                        <>
                            <div className={cx(style.calendarLayer, { [style.calendarLayerReady]: showData !== 'calendar' })} id="calendarLayer" onClick={() => {
                                if (showData !== 'calendar'){
                                    actionShowCalendar();
                                }
                            }}>
                                <div className={cx(style.dateLabel, { [style.date]: !!state.claimData.airlineDateLanding })}>{t('common.requiredField')}</div>
                                <div className={style.dateValue}>{state.claimData.airlineDateLanding ? formatDateShortOnly(state.claimData.airlineDateLanding) : ''}</div>
                                <CalendarIcon className={style.calendar} />
                            </div>
                        </>
                    }
                    {showData === 'calendar' &&
                        <div className={style.layerClickCalendar}>
                            <Calendar
                                value={state.claimData.airlineDateLanding}
                                onChange={onChangeCalendar}
                                locale={language || 'en'}
                                maxDate={new Date()}
                                minDate={getDaysAgo(730)}
                            />
                        </div>
                    }
                </>
            }


            {(state.claimData.clientLocationAirlineCountry.code && (showData === 'countrys' || showData === null)) &&
                <>
                    <div className={style.header}>
                        {t('createClaim.refCountry')}
                    </div>

                    <DropDownSearch
                        label={t('common.requiredField')}
                        data={countrys.map(c => {
                            return { text: c.text, value: c.value }
                        })}
                        onChange={(data) => actionChangeCountry(data)}
                        value={state.claimData.clientLocationAirlineCountry.name}
                        className={style.inputLayer}
                        t={t}
                        search
                        onExpand={expand => setShowData(expand ? 'countrys' : null)}
                        id={"search_new_claim_country_landing"}
                    />
                </>
            }
            {state.loadingAirline &&
                <div className={style.loadingAirline}>
                    <Loader />
                </div>
            }

        </div>
    )
}

export default React.memo(Airline);