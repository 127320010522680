import React from 'react';
import styles from './DrawerHeader.module.scss';

type HeaderSectionProps = {
    title: string;
};

const DrawerHeader: React.FC<HeaderSectionProps> = ({ title }) => {
    return (
            <div className={styles.paddingHeader}>{title}</div>
    );
};

export default DrawerHeader;