import React from 'react';

import { ContactBranch, FeedMessageLine } from "../../DataStructure/Components";
import { useReportTagTranslation } from "../../DataStructure/hooks";
import { RequiredDocumentT } from "../../DataStructure/interfaces";
import style from './OriginalDocuments.module.scss';

type MainPropsT = {
    t: (data: string, params?: any) => string,
    branch: string,
    missingOriginalDocuments: RequiredDocumentT[]
}

const OriginalDocuments: React.FC<MainPropsT> = ({ t, branch, missingOriginalDocuments }) => {

    const getTitle = useReportTagTranslation(t);

    return (
        <div className={style.root}>
            <FeedMessageLine
                data={[
                    [
                        <>
                            <div className={style.title}>
                                {t('chatMessages.reqDocsOriginal')}
                            </div>
                            <ul>
                                {missingOriginalDocuments.map(doc => (
                                    <li key={`doc-${doc.Name}`}><strong>{getTitle(doc.Name)}</strong></li>
                                ))}
                            </ul>
                        </>,
                        <ContactBranch title={t('chatMessages.reqDocsOriginalContactHeader')} hideDirectContact branch={branch} contactClassName={style.contactAddress} />
                    ]
                ]}
                dataOverText={{ text: t('caseStatuses.VALIDATION_PAX') }}      
            />
        </div>
    );
}
export default OriginalDocuments;