import React, { useLayoutEffect, useMemo } from 'react';
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { ClaimT, setShowContact, VerdictT } from "../../DataStructure/interfaces";
import { FeedMessageLine } from "../../DataStructure/Components";
import { useDeviceType } from "../../DataStructure/hooks";

type MainPropsT = {
    claim: ClaimT,
    t: (data: string, params?: any) => string,
    verdict?: VerdictT,
    blockReason?: string
}

const Blocked: React.FC<MainPropsT> = ({ claim, t, verdict, blockReason }) => {

    const dispatch = useDispatch();
    const { isMobile } = useDeviceType();
    const navigate = useNavigate();

    const reason  = useMemo(() => {
        if (verdict?.resolution?.moneyCompensationUrl){
            return t('chatMessages.sparePartsNoUrl');
        }
        return null;
    }, [verdict?.resolution?.moneyCompensationUrl, t]);

    const textReason = useMemo(() => (): string => {
        if (blockReason && blockReason === 'TK_GERMANY_POSTAL_CODE'){
            return 'common.noServeAirline';
        }
        return 'caseDetails.blockedClaim';
    }, [blockReason]);

    useLayoutEffect(() => {
        const infoContact = document.querySelector("#infoContact strong");

        const clickEvent = (event: any) => {
            if (isMobile){
                navigate(`/${claim.id}/contact`);
            } else {
                dispatch(setShowContact(claim.id));
            }
        }

        if (infoContact){
            infoContact.addEventListener('click', clickEvent);
            return () => {
                infoContact.removeEventListener('click', clickEvent);
            }
        }
    }, [dispatch, claim.id, isMobile, navigate]);

    const dataFeed = useMemo(() => [
        reason ? 
            <>{reason}</>:
            t(textReason(), { caseNumber: `<strong>${claim.caseNumber}</strong>`, airlineName: `<strong>${claim.airlineName}</strong>` })
    ], [t, claim.airlineName, claim.caseNumber, textReason, reason]);

    return (
        <>
            <FeedMessageLine
                data={dataFeed}
                spaceBottom
            />
        </>
    );
}
export default Blocked;