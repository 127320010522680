import React from 'react';

import { FeedMessageLine } from "../../DataStructure/Components";

type MainPropsT = {
    t: (data: string, params?: any) => string
} 

const AutomaticVerdictStarted: React.FC<MainPropsT> = ({ t }) => {
    return (
        <>
            <FeedMessageLine
                data={t(`chatMessages.waitingAutoVerdict`)}
            />
        </>
    );
}
export default AutomaticVerdictStarted;