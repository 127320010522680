import { useSelector } from 'react-redux';

import { actionShowCookies } from "Helpers/browser";
import styles from './HeaderDesktop.module.scss';
import useTranslate from "Hooks/useTranslate";
import { AppStateT } from 'Store/modules';
import { useMemo, useState } from "react";
import { ReactComponent as CookieIcon } from 'Assets/cookies-settings-icon.svg';
import cx from 'classnames';
import DrawerMenu from 'Components/DrawerMenu/DrawerMenu';
import { helpCenterAvailableLanguages } from 'Helpers/i18';


export default function HeaderDesktop() {
    const t = useTranslate();
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
    const [open, setOpen] = useState(false);
    const toggleDrawer = (newOpen: boolean) => setOpen(newOpen);

    const HAMBURGER_ELEMENTS = useMemo(() => [
        { name: t('common.personalInformation'), path: '/personalInfo' },
        ...(actualLanguage === 'pl' || actualLanguage === 'en-us' ? [{ name: t('common.helpCenter'), path: '/help' }] : []),
        { name: 'footer.language', path: '/lang' },
        { name: 'footer.privacyPolicy', path: '/privacyPolicy' },
        { name: 'footer.termsAndConditions', path: '/terms' },
        { name: t('common.logOut'), path: '/logOut' }
    ], [actualLanguage, t]);  

    const footerLinksFirstRow = useMemo(() => 
        HAMBURGER_ELEMENTS.filter(link => link.name === 'footer.help' ? helpCenterAvailableLanguages.includes(actualLanguage) : true), 
    [HAMBURGER_ELEMENTS, actualLanguage]);  

    return (
        <header className={styles.root}>
            <div className={styles.iconContainer}>
                 <div
                    className={cx(styles.icon, styles.iconCookie)}
                    onClick={actionShowCookies}
                    title={t('common.cookiesInfo')}
                > 
                    <CookieIcon />
                </div> 
                <div className={cx(styles.icon)}>
                    <DrawerMenu
                        open={open}
                        toggleDrawer={toggleDrawer}
                        footerLinksFirstRow={footerLinksFirstRow}
                    />
                </div>
            </div>
        </header>
  );
}
