import React from 'react';

import{ FeedMessageLine } from "../../../DataStructure/Components";

type MainPropsT = {
    t: (data: string, params?: any) => string
}

const PickupVerdict: React.FC<MainPropsT> = ({ t }) => {

    return (
        <FeedMessageLine
            data={[
                t('chatMessages.verdictReady'),
                t('chatMessages.caseIs') + ` <strong style="text-transform: lowercase">${t('verdictTypes.repairable')}</strong>`
            ]}
            spaceBottom
        />
    );
}
export default PickupVerdict;