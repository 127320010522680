import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import cx from 'classnames';

import { OrderT, OrderStatusE, OrderProductT, PageConfigT, PaymentStatusesE, PaymentsE, AppStateT } from "../../../../DataStructure/interfaces";
import { useDeviceType, useFormattedPrice } from "../../../../DataStructure/hooks";
import styles from './Order.module.scss';

type MainPropsT = {
    order?: OrderT,
    staticOrderData?: boolean
}

const Order: React.FC<MainPropsT> = ({ order, staticOrderData }) => {

    const { t } = useTranslation();
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const formatPrice = useFormattedPrice(order.currency, pageConfig.noFractionCurrencies);
    const statusInHeader = useMemo(() => !!order.products.some(p => !!p.status) || order.products.filter(p => p.status !== order.status).length === 0 || order.products.length < 2, [order]);
    const orderNumber = useMemo(() => order.orderNumber || order.salesforceOrderNumber, [order.salesforceOrderNumber, order.orderNumber]);

    const { isMobile } = useDeviceType();

    const getStatusTitle = useMemo(() => (status: string): string => {

        switch (status) {
            case OrderStatusE.DRAFT: return t('order.titleDraft');
            case OrderStatusE.ACTIVATED: return t('order.titleActive');
            case OrderStatusE.ORDERED_DROPSHIPPING: case OrderStatusE.FULFILLED: return t('order.titleSend');
            case OrderStatusE.DEACTIVATED: return t('order.titleCanceled');
            case OrderStatusE.COMPLETED: return t('order.titleCopleted');
            case OrderStatusE.ORDER_IN_PROCESS: return t('order.titleProcessing');
            default: return t('order.titleProcessing');
        }

    }, [t]);

    const getImageUrl = useMemo(() => (product: OrderProductT) => {
        if (product.photoUrl) return product.photoUrl;
        return '';
    }, []);

    const hasDeliveryDaysContent = useMemo(() => (daysAmount: string): boolean => {
        const keyTranslate = 'shop.deliveryDays'
        if (t(keyTranslate, { deliveryDays: daysAmount }) !== keyTranslate) {
            return true
        }
        return false
    }, [t]);

    const getDeliveryName = useMemo(() => (name: string): string => {
        const translateName = t(`shop.${name}`);
        if (translateName !== name) return translateName;
        return name;
    }, [t]);

    const deliveryDaysContent = useMemo(() => (daysAmount: string): string | null => {
        const keyTranslate = 'shop.deliveryDays'
        if (t(keyTranslate, { deliveryDays: daysAmount }) !== keyTranslate) {
            return t(keyTranslate, { deliveryDays: daysAmount })
        }
        return null
    }, [t]);

    const statusPaymentText = useMemo(() => (status: OrderT['payment']['status']): string => {
        if (status === PaymentStatusesE.COMPLETED) {
            return t('order.payStatusSuccess');
        }
        if (status === PaymentStatusesE.FAILED) {
            return t('order.payStatusFailed');
        }
        return t('order.payStatusProcessing');
    }, [t]);

    const getPaymentName = useMemo(() => (type: string) => {
        switch (type.toUpperCase()) {
            case PaymentsE.WIRE_TRANSFER: return t('shop.bankWire');
            case PaymentsE.CREDIT_CARD: return t('shop.creditCard');
            case PaymentsE.PAYPAL: return t('shop.paypal');
            case PaymentsE.CASH_ON_DELIVERY: return t('shop.cashOnDelivery');
            default: return type;
        }
    }, [t]);

    return (
        <>
            <div className={cx(styles.order, { [styles.canceled]: order.status === 'DEACTIVATED', [styles.completed]: order.status === 'COMPLETED', [styles.mobile]: isMobile })}>
                {orderNumber &&
                    <div className={styles.orderNumber}>
                        <div className={styles.orderData}>
                            {t('order.orderNumber')}: <strong>{orderNumber}</strong>
                        </div>
                    </div>
                }
                {(statusInHeader && !staticOrderData) &&
                    <div className={styles.header}>
                        {getStatusTitle(order.status)}
                    </div>
                }
                <div className={styles.products}>
                    {order.products.map((product: OrderProductT) => (
                        <div
                            className={cx(styles.product, { [styles.deactivated]: product.status === OrderStatusE.DEACTIVATED })}
                            key={`productOrder_${product.name}${product.size}${product.color}`}
                        >
                            <div className={styles.image}><img src={getImageUrl(product)} alt="" /></div>
                            <div className={styles.content}>
                                <div className={styles.title}>{product.name}</div>
                                {(product.deliveryDays && hasDeliveryDaysContent(product.deliveryDays) && product.status !== OrderStatusE.DEACTIVATED) &&
                                    <div className={styles.deliveryDays}>{parse(deliveryDaysContent(product.deliveryDays))}</div>
                                }
                                <div className={styles.price}><span className='price'>{formatPrice(product.amount)}{' '}{order.shipment.deliveryMethod.currency.code}</span></div>
                                {(!statusInHeader && !staticOrderData) &&
                                    <div className={styles.status}>
                                        {getStatusTitle(product.status)}
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.subheader}>{t('shop.formDeliveryMethod')}:</div>
                <div className={styles.value}>{getDeliveryName(order.shipment.deliveryMethod.name)}</div>
                <div className={styles.subheader}>{t('shop.paymentMethod')}:</div>
                <div className={styles.value}>{order.payment?.selectedType ? getPaymentName(order.payment.selectedType) : '-'}</div>
                {(order?.payment && order.payment.selectedType !== 'CASH_ON_DELIVERY' && !staticOrderData) &&
                    <>
                        <div className={styles.subheader}>{t('order.payStatusTitle')}:</div>
                        <div
                            className={cx(styles.value, {
                                [styles.error]: order.payment.status === PaymentStatusesE.FAILED,
                                [styles.success]: order.payment.status === PaymentStatusesE.COMPLETED,
                                [styles.warning]: order.payment.status === PaymentStatusesE.PROCESSING || order.payment.status === PaymentStatusesE.REDIRECT,
                            }
                            )}
                        >
                            {statusPaymentText(order.payment.status)}
                        </div>
                        {(order.status === 'DRAFT' && ((order.payment.status === 'REDIRECT' && order.payment.redirectUrl) || order.payment.status === 'FAILED')) &&
                            <div className={cx(styles.redirectButton)}>
                                <div className={styles.redirectBody}>
                                    {t(`order.${order.payment.status === 'REDIRECT' ? 'finishRedirect' : 'finishRedo'}`)}
                                </div>
                            </div>
                        }
                    </>
                }
                <div className={styles.subheader}>{t('shop.formDelivery')}:</div>
                <div className={styles.value}>{order.shipment.firstName} {order.shipment.lastName}, {order.shipment.location.address}, {order.shipment.location.zipCode} {order.shipment.location.city}, {order.shipment.location.country}</div>
                <div className={styles.subheader}>{t('shop.summary')}:</div>
                <div className={styles.value}>{t('shop.voucherTitle')}: <strong>{formatPrice(order.amountVoucher)}{' '}{order.shipment.deliveryMethod.currency.code}</strong></div>
                <div className={styles.value}>{t('order.totalPay')}: <span className={styles.total}><strong>{formatPrice(order.amountTotal)}{' '}{order.shipment.deliveryMethod.currency.code}</strong></span></div>
            </div>
        </>
    );
}
export default Order;