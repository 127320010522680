import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import { clearCart, setOrderResult, ShopStateT } from 'Store/modules/luggageStore/shop';
import type { ClaimChatStateT } from 'Store/modules/claimChat';
import { StoreModal } from "PageComponents/LuggageStore";
import AlertInfo from 'Components/AlertInfo/AlertInfo';
import { reload } from "Store/modules/claimChat/data";
import { runNotify } from 'Helpers/notifications';
import type { AppStateT } from 'Store/modules';
import styles from './FeedPage.module.scss';
import Feed from "PageComponents/Feed/Feed";

type ChatRouteParams = {
    match?: {
        params: {
            id: string;
        }
    }
};

export default function FeedPage({ match }: ChatRouteParams) {
    const claimId = match.params.id;
    const { claim } = useSelector<
        AppStateT,
        Pick<ClaimChatStateT['data'], 'claim' | 'verdict' | 'resolution'>
    >(({ claimChat }) => ({
        claim: claimChat.data.claim,
        verdict: claimChat.data.verdict,
        resolution: claimChat.data.resolution,
    }));
    const { orderResult } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { action } = useParams<any>();
    const [showAlertSpareParts, setShowAlertSpareParts] = useState<boolean>(!!action && (action === 'successSpareParts' || action === 'errorSpareParts'));
    const navigate = useNavigate();

    useEffect(() => {
        if (orderResult.result){
            // clear cart
            dispatch(clearCart());
            runNotify({ message: `The order has been sent`, type: 'success'});
        } else if (orderResult.result === false){
            // send message
            runNotify({ message: orderResult.commentResult, type: 'error'});
            // clear result
            dispatch(setOrderResult({ commentResult: '', process: false, result: null }));
        }
    }, [orderResult, dispatch]);

    const [storeIsOpen, setStoreIsOpen] = useState<boolean>(false);

    function openStore() {
        setStoreIsOpen(true);
    }

    function closeStore() {
        setStoreIsOpen(false);
    }

    return (
        <div className={styles.root}>
            <Feed claimId={claimId} openStore={openStore} />
            <StoreModal 
                open={storeIsOpen}
                onClose={closeStore}
                claim={claim}
            />
            {showAlertSpareParts &&
                <AlertInfo
                    actionClose={() => {
                        setShowAlertSpareParts(false);
                        dispatch(reload());
                        navigate(`/${claim.id}`, { replace: true });
                    }}
                    description={action === 'successSpareParts' ? t('chatMessages.sparePartsModalSuccess') : t('chatMessages.sparePartsModalError')}
                    type={action === 'successSpareParts' ? 'success' : 'error'}
                    isMobile={false}
                />
            }
        </div>
    );
}
