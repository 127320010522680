import React, { useMemo } from 'react';

import { FeedMessageLine } from "../../DataStructure/Components";
import { CLAIM_STATUSES } from "../../DataStructure/interfaces";
import { getFeedTypeData } from "../../DataStructure/helpers";

type MainPropsT = {
    t: (data: string, params?: any) => string
}

const AssessmentPending: React.FC<MainPropsT> = ({ t }) => {

    const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.ASSESSMENT_PENDING, t), [t]);

    return (
        <FeedMessageLine
            data={dataShow}
        />
    );
}
export default AssessmentPending;