import parse from 'html-react-parser';
import cx from 'classnames';
import React from 'react';

import ImgTrustPilot from 'Assets/reviews/trus-pilot.png';
import ImgGoogle from 'Assets/reviews/google.png';

import FeedMessageLine from "Components/FeedMessageLine/FeedMessageLine";
import { useDeviceType } from "Helpers/responsiveContainers";
import style from './ChatGoogleReview.module.scss'
import { CaseT } from 'api/modules/claim';

type MainPropsT = {
    review: CaseT['review'],
    t: (data: string, params?: any) => string,
    hideHeaderText?: boolean,
    marked?: boolean
}

const ChatGoogleReview: React.FC<MainPropsT> = ({ review, t, hideHeaderText, marked }) => {

    const { isMobile } = useDeviceType();

    return (
        <FeedMessageLine
            data={<div className={cx(style.root, { [style.rootMobile]: isMobile, [style.rootMarked]: marked })}>
                {!hideHeaderText &&
                    <div className={style.header}>{parse(t('chatMessages.reviewMain'))}</div>
                }
                {review.trustPilotUrl &&
                    <div className={style.link}>
                        <a href={review.trustPilotUrl} target="_blank" rel="noreferrer"><img src={ImgTrustPilot} alt="" /></a>
                    </div>
                }
                {review.googleMapsUrl &&
                    <>
                        {review.trustPilotUrl && <div className={style.orGoogle}>{t('chatMessages.reviewGoogle')}</div>}
                        <div className={style.link}>
                            <a href={review.googleMapsUrl} target="_blank" rel="noreferrer"><img src={ImgGoogle} alt="" /></a>
                        </div>
                    </>
                }
                <div className={style.finish}>{t('chatMessages.reviewFinish')}</div>
            </div>}
        />
    )
}
export default ChatGoogleReview;