import { add, format, set, parseISO, differenceInMonths, subDays, isToday, addSeconds, differenceInSeconds, differenceInDays, differenceInYears, intervalToDuration } from 'date-fns';
import { pl, ko, pt, hu, sk, cs, enUS, zhCN, ja, fr, it, es, de } from 'date-fns/locale';

const DAY_NUMBER_TO_VALUE = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const localesObj = {
    'jp': ja,
    'ja': ja,
    'kr': ko,
    'ko': ko,
    'cn': zhCN,
    'zh': zhCN,
    'pl': pl,
    'de': de,
    'cs': cs,
    'es': es,
    'sk': sk,
    'hu': hu,
    'it': it,
    'fr': fr,
    'pt': pt,
}

export const addSecondsToISODate = (isoDate: string, secondsToAdd: number): Date => {
    const date = parseISO(isoDate);
    return addSeconds(date, secondsToAdd);
}

export function formatDateWithTime(date: number | Date) {
    return format(date, 'dd.MM.yyyy HH:mm');
}

export function formatDateShortOnly(date: number | Date) {
    return format(date, 'dd.MM.yyyy');
}

export function addDateHours(originalDate: Date, addHours: number = 0) {
    let newDate = new Date(originalDate);
    newDate.setHours(newDate.getHours() + addHours);
    return newDate;
}

export function formatDateOnly(date: number | Date) {
    return format(date, 'dd LLL, yyyy');
}

export function formatTimeOnly(date: number | Date) {
    return format(date, 'h:mm a');
}

export function formatDatePickerDate(date: Date, lang?: string) {
    return format(date, 'MMM, EEE dd', {
        locale: getLocale(lang || 'en')
    });
}

const getLocale = (language: string) => {
    for (const [key, value] of Object.entries(localesObj)) {
        if (language.toLowerCase().includes(`${key}`)) {
            return value;
        }
    }
    return enUS;
}

export function formatDateMonth(date: Date, language: string = 'en-EN') {
    return format(date, 'LLLL, yyyy', { locale: getLocale(language) });
}

export function formatDateSaleforce(date: Date): string {
    return format(date, 'yyyy-MM-dd');
}

export function formatDateMonthSimple(date: Date): string {
    return format(date, 'yyyy-MM');
}

export function getStartOfTheDay(date: Date) {
    return set(date, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

export function getMiddleOfTheDay(date: Date) {
    return set(date, {
        hours: 12,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

export function getAgeName(months: number, t: (data: string, p?: any) => string): string {

    if (months === 0) {
        return t('common.ageThisMonth');
    }
    if (months === 1) {
        return t('common.age1Month');
    }
    if (months < 12) {
        return t('common.ageMonths2', { months });
    }
    if (months === 12) {
        return t('common.age1year');
    }
    return t('common.ageYears2', { years: Math.ceil(months / 12) });
}

export function getClosestWorkingDay(): Date {
    const now = new Date();
    const dayOfWeek = now.getDay();

    // Sprawdź, czy dzisiaj jest sobota lub niedziela
    if (dayOfWeek === 6) { // sobota
        now.setDate(now.getDate() + 2); // ustaw na poniedziałek
    } else if (dayOfWeek === 0) { // niedziela
        now.setDate(now.getDate() + 1); // ustaw na poniedziałek
    }

    // Sprawdź, czy jutro jest sobota lub niedziela
    now.setDate(now.getDate() + 1);
    const nextDayOfWeek = now.getDay();

    if (nextDayOfWeek === 6) { // sobota
        now.setDate(now.getDate() + 2); // ustaw na poniedziałek
    } else if (nextDayOfWeek === 0) { // niedziela
        now.setDate(now.getDate() + 1); // ustaw na poniedziałek
    }
    return now;
}

export const addDate = add;

function to12hTime(time: string) {
    const [h, m] = time.split(':');
    const h12 = +h % 12 || 12;

    const ampm = Number(h) < 12 || Number(h) === 24 ? 'AM' : 'PM';

    return `${h12}:${m}${ampm}`;
}

export function getWorkingHours(day: any) {
    return `${to12hTime(day.open)} - ${to12hTime(day.close)}`;
}

function generatePeriod(start: any, end: any) {
    const isSingleDay = start === end;

    return isSingleDay
        ? `${DAY_NUMBER_TO_VALUE[start.day]} ${to12hTime(start.open)} - ${to12hTime(start.close)}`
        : `${DAY_NUMBER_TO_VALUE[start.day]}-${DAY_NUMBER_TO_VALUE[end.day]} ${getWorkingHours(start)}`;
}

export function getWorkingPeriod(days: any) {
    if (!days || days.length === 0) {
        return [];
    }

    const workingPeriod = [];
    const sortedDays = [...days].sort((a, b) => a.day - b.day);

    let start = sortedDays[0];
    let end = sortedDays[0];

    for (let i = 0; i < sortedDays.length; i++) {
        const day = sortedDays[i];

        if (end.open !== day.open || end.close !== day.close || (end.day + 1 !== day.day && i !== 0)) {
            workingPeriod.push(generatePeriod(start, end) as never);

            start = day;
            end = day;
        } else {
            end = day;
        }

        if (i === sortedDays.length - 1) {
            workingPeriod.push(generatePeriod(start, end) as never);
        }
    }

    return workingPeriod;
}

export function getNextBusinessDate(date: Date): Date {
    const days = new Array(7);
    let nextDate = date;
    for (let i = 0; i < 7; i++) {
        days[nextDate.getDay()] = new Date(nextDate);
        nextDate.setDate(nextDate.getDate() + 1);
    }

    return days[Math.min((date.getDay() + 6) % 7 + 1, 5) % 5 + 1];
}

export const getSecondsFromStringDate = (stringDate: string): number => {
    if (!stringDate.match(/^(19\d{2}|2\d{3})-((0[13578]|1[02])-([0-2]\d|3[01])|02-[0-2]\d|(0[469]|11)-([0-2]\d|30))T([01]\d|2[0-4])(:[0-5]\d){2}(\.\d{3})?(Z|([+-]([01]\d|2[0-3]):[0-5]\d))$/)) {
        return 0;
    }
    return Math.floor(new Date(stringDate).getTime() / 1000);
}

export const getSecondsActual = (stringDate: string): number => {

    const seconds = getSecondsFromStringDate(stringDate);
    if (seconds > 0) {
        return Math.floor(new Date().getTime() / 1000) - seconds;
    }
    return 0;
}

export const dateToString = (date: Date, seconds: boolean = false): string => {
    if (seconds) {
        return `${date.getFullYear()}-${zFill(date.getMonth() + 1)}-${zFill(date.getDate())} ${zFill(date.getHours())}:${zFill(date.getMinutes())}:${zFill(date.getSeconds())}`;
    }
    return `${date.getFullYear()}-${zFill(date.getMonth() + 1)}-${zFill(date.getDate())} ${zFill(date.getHours())}:${zFill(date.getMinutes())}`;
}

const zFill = (number: number) => {
    return number < 10 ? '0' + number : number;
}


export const stringToDate = (dateStr: string, offset = 0): Date => {
    const date = new Date(dateStr);

    const year = +(date.toString().substr(0, 4));
    const month = +(date.toString().substr(5, 2));
    const day = +(date.toString().substr(8, 2));

    const hour = +(date.toString().substr(11, 2));
    const minute = +(date.toString().substr(14, 2));
    const second = +(date.toString().substr(17, 2));

    if (offset > 0) {
        const d = new Date(year, month - 1, day, hour, minute, second);
        const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        return new Date(utc + (1000 * offset));
    }

    return new Date(year, month - 1, day, hour, minute, second);
}

export const addSecondsToDate = (dateString: string, seconds: number): string => {
    const date = new Date(dateString);
    return dateToString(new Date(date.getTime() + (seconds * 1000)));
}

export const isCurrentMonth = (date: Date): boolean => {
    const currentDate = new Date();

    if (
        date.getFullYear() === currentDate.getFullYear() &&
        date.getMonth() === currentDate.getMonth()
    ) {
        return true; // Data jest z bieżącego miesiąca
    }

    return false; // Data nie jest z bieżącego miesiąca
}

export const getDayOfMonth = (date: Date): number => {
    return date.getDate();
}

export const monthsSinceDate = (inputDateStr: string): number => {
    const inputDate = parseISO(inputDateStr);
    return differenceInMonths(new Date(), inputDate);
}

export const getDaysAgo = (days: number): Date => {
    const today = new Date();
    return subDays(today, days);
}

export const getDateYearsFromNow = (years: number): Date => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + years);
    return today;
}

const langCodeAdapter = (code: string) => {
    switch (code) {
        case 'en-us': return 'en';
        case 'cs-cz': return 'cs';
        case 'pt-pt': return 'pt';
    }
    return localesObj?.[code] || 'en';
}

export const chatFormatDate = (date: Date, languageCode: string) => {
    if (isToday(date)) {
        return format(date, 'p', { locale: localesObj[langCodeAdapter(languageCode)] }); // np. "11:12 AM"
    } else {
        return format(date, 'd MMMM yyyy, p', { locale: localesObj[langCodeAdapter(languageCode)] }); // np. "12 marzec 2024, 11:12 AM"
    }
}

// zwraca różnicę w sekundach ikle minęło od obecnej daty od daty podanej w parametrze
export const secondsSince = (dateString: string): number => {
    const pastDate = parseISO(dateString);
    const now = new Date();
    return differenceInSeconds(now, pastDate);
}

export const getSecondsUntil = (targetDate: Date): number => {
    const now = new Date();
    return differenceInSeconds(targetDate, now);
}

export function getSecondsDifference(startDateString: string, endDateString: string): number {
    const startDate = parseISO(startDateString);
    const endDate = parseISO(endDateString);
    const now = new Date();
  
    if (now > endDate) {
        return -1;
    }
  
    const effectiveStartDate = now > startDate ? now : startDate;
  
    return differenceInSeconds(endDate, effectiveStartDate);
}

export const formatCountdown = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const minutesString = String(minutes).padStart(2, '0');
    const secondsString = String(remainingSeconds).padStart(2, '0');

    return `${minutesString}:${secondsString}`;
}

export const daysSince = (dateString: string): number => {
    const date = parseISO(dateString);
    const now = new Date();
    return differenceInDays(now, date);
}

export const calculateDateDifference = (date1: string, date2: string): { years: number, months: number } => {
    if (!date1 || !date2) return {
        months: 0,
        years: 0
    };

    const parsedDate1 = parseISO(date1);
    const parsedDate2 = parseISO(date2);

    let yearsDifference = differenceInYears(parsedDate2, parsedDate1);

    let monthsDifference = differenceInMonths(parsedDate2, parsedDate1) - (yearsDifference * 12);

    if (monthsDifference < 0) {
        yearsDifference -= 1;
        monthsDifference += 12;
    }

    return {
        years: yearsDifference,
        months: monthsDifference
    };
}

export const addMinutesToCurrentTime = (minutes: number): Date => {
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + minutes);
    return currentTime;
}

export function formatSecondsToTimeString(totalSeconds: number): string {
    const duration = intervalToDuration({ start: 0, end: totalSeconds * 1000 });
    const { days, hours, minutes, seconds } = duration;
  
    const pad = (num: number): string => num.toString().padStart(2, '0');
  
    if (days && days > 0) {
        return `${pad(days)}:${pad(hours || 0)}:${pad(minutes || 0)}:${pad(seconds || 0)}`;
    } else if (hours && hours > 0) {
        return `${pad(hours)}:${pad(minutes || 0)}:${pad(seconds || 0)}`;
    } else {
        return `${pad(minutes || 0)}:${pad(seconds || 0)}`;
    }
}