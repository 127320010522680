import React from 'react';

import { FeedMessageLine } from "../../DataStructure/Components";

type MainPropsT = {
    t: (data: string, params?: any) => string
}

const OneToOneReplacement: React.FC<MainPropsT> = ({ t }) => {
    return (
        <FeedMessageLine
            data={t('chatMessages.pendingOrderReplacement')}
        />
    );
}
export default OneToOneReplacement;