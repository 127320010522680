import React, { useMemo } from 'react';

import { CaseT, CLAIM_STATUSES } from "../../DataStructure/interfaces";
import ChatGoogleReview from "../ChatGoogleReview/ChatGoogleReview";
import { FeedMessageLine } from "../../DataStructure/Components";
import { getFeedTypeData } from "../../DataStructure/helpers";

type MainPropsT = {
    t: (data: string, params?: any) => string,
    review?: CaseT['review'],
    claimId: string
}

const Closed: React.FC<MainPropsT> = ({ t, review, claimId }) => {

    const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.CLOSED, t, { urlTrustPilot: review?.trustPilotUrl, urlGoogleReview: review?.googleMapsUrl }, null, !review), [t, review]);
    const isShowOpinions = useMemo(() => !!review?.googleMapsUrl || !!review?.trustPilotUrl, [review?.googleMapsUrl, review?.trustPilotUrl]);

    return (
        <>
            <FeedMessageLine
                data={isShowOpinions ? t('caseStatusesBasic.CLOSED_MAIN') : dataShow}
                spaceBottom
                marked
            />
            {isShowOpinions &&
                <ChatGoogleReview
                    t={t}
                    review={{
                        googleMapsUrl: review.googleMapsUrl,
                        trustPilotUrl: review.trustPilotUrl
                    }}
                    hideHeaderText
                    marked
                />
            }
        </>
    );
}
export default Closed;