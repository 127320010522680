import React, { useMemo } from 'react';

import { FeedMessageLine } from "../../../DataStructure/Components";
import { OrderT } from "../../../DataStructure/interfaces";
import { useOrder } from "../../../DataStructure/hooks";
import Order from "./Order/Order";

type MainPropsT = {
    order: OrderT,
    disabled: boolean,
    orders?: OrderT[],
}

const CardOrder: React.FC<MainPropsT> = ({ order: orderProps, disabled, orders = [] }) => {

    const orderActual = useOrder(orders);
    const order = useMemo(() => orderActual || orderProps, [orderActual, orderProps]);

    return (
        <FeedMessageLine
            data={<Order order={order} staticOrderData />}
            disabled={disabled}
        />
    );
}
export default CardOrder;