import React, { useMemo } from 'react';

import { calculateDateDifference, getAgeName, getFeedTypeData } from "../../../DataStructure/helpers";
import { CLAIM_STATUSES, VerdictT } from "../../../DataStructure/interfaces";
import { FeedMessageLine } from "../../../DataStructure/Components";
import style from './MCByDolfi.module.scss';

type MainPropsT = {
    t: (data: string, params?: any) => string,
    verdict: VerdictT,
    airlineName: string,
    disabled: boolean
}

// [SOLUTION_REFUND_PENDING]
const MCByDolfi: React.FC<MainPropsT> = ({ t, verdict, airlineName, disabled }) => {

    const diffData = useMemo(() => calculateDateDifference(verdict.purchaseDate, verdict.createdAt), [verdict.purchaseDate, verdict.createdAt]);
    const dataEntry = useMemo(() => {
        return {
            residualValue: `<strong>${verdict.resolution.residualValue}</strong>`,
            currency: `<strong>${verdict.currencyIsoCode}</strong>`,
            airlineName,
            templateValue: verdict.value,
            baggageAge: getAgeName((diffData.years * 12) + diffData.months, t),
            voucherValue: verdict.resolution.voucher,
            refund: verdict.resolution.refund,
            urlForm: verdict.resolution.moneyCompensationUrl
        };
    }, [verdict, diffData, t, airlineName]);

    const dataShow = useMemo(() => getFeedTypeData(CLAIM_STATUSES.SOLUTION_REFUND_PENDING, t, dataEntry), [t, dataEntry]);

    return (
        <div className={style.root}>
            <FeedMessageLine
                data={[
                    t('chatMessages.verdictReady')
                ]}
                spaceBottom
            />
            {dataEntry.voucherValue ?
                <FeedMessageLine
                    data={dataShow}
                    spaceBottom
                />:
                <FeedMessageLine
                    data={
                        t(
                            'chatMessages.moneyCompensationResultLabel',
                            { 
                                url: disabled ? `<span className="stroke">${t('chatMessages.form')}</span>` : `<a href="${dataEntry.urlForm}">${t('chatMessages.form')}</a>`,
                                price: `<strong>${dataEntry.refund}${dataEntry.currency}</strong>`
                            }
                        )
                    }
                />
            }
        </div>
    );
}
export default MCByDolfi;