import React, { useState, useCallback, useReducer, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import parse from 'html-react-parser';
import Holidays from 'date-holidays';
import { isWeekend } from "date-fns";
import cx from 'classnames';

import { ReactComponent as CalendarIcon } from 'Assets/calendar.svg';

import { getMiddleOfTheDay, getNextBusinessDate, formatDatePickerDate, formatDateSaleforce } from 'Helpers/date';
import { Button, ChatMessageGroup, Input, LocationSearch, Loader, MessageModal } from 'Components';
import runApi, { ApiSendMethods, ResultApiTypeT } from 'api/base/runApi';
import { setUser, type StateT as UserStateT } from 'Store/modules/user';
import chatStyles from 'PageComponents/Chat/Chat/Chat.module.scss';
import { useDeviceType } from 'Helpers/responsiveContainers';
import { countriesCodes } from 'Helpers/languageCode';
import { reload } from 'Store/modules/claimChat/data';
import Checkbox from 'Components/Checkbox/Checkbox';
import styles from './RepairPickUpForm.module.scss';
import { runNotify } from 'Helpers/notifications';
import type { AppStateT } from 'Store/modules';
import { validField } from 'Helpers/validForm';
import useTranslate from "Hooks/useTranslate";
import { PaymentsE } from "Store/Types/order";
import { defaultLanguage } from "Helpers/i18";
import { formatInput } from 'Helpers/strings';
import { AddressT } from 'Helpers/googleApi';
import { Store } from './Store';

interface IRepairPickUpForm {
    loading: boolean;
    error: string | null;
    claimId: string,
    initialValues: {
        name: string;
        location: LocationT;
        date: Date;
    };
    sideBarRef?: React.MutableRefObject<any>;
    close: () => void;
    openSaveASDraftModal: () => void;
    draftData?: any;
    isBlocked: boolean
}

export type LocalStateT = {
    name: string,
    location: LocationT,
    floor: string,
    date: Date | null,
    courierNotes: 'door' | 'reception' | 'porch',
    description: string,
    email: string,
    phoneNumber: string,
    valid: boolean,
    validCountry: boolean,
    sending: boolean
    costExtraChecked: boolean,
    config: {
        extraService: {
            cost: number|null,
            currencyIsoCode: string,
            options: string[],
            payment: {
                options: string[]
            },
            symbol: string
        },
        pickup: {
            blocked: {
                byZipCodes: string[]
            },
            countries: string[]
        }
    }
}

const initialValues: LocalStateT = {
    name: '',
    location: {
        address: '',
        city: '',
        country: '',
        zipCode: '',
        coords: {
            lat: 0,
            lng: 0
        },
        countryCode: '',
        formattedAddress: ''
    },
    floor: '',
    date: null,
    courierNotes: 'door',
    description: '',
    email: '',
    phoneNumber: '',
    valid: false,
    validCountry: false,
    sending: false,
    costExtraChecked: false,
    config: {
        extraService: {
            cost: null,
            currencyIsoCode: 'PLN',
            options: [],
            payment: {
                options: []
            },
            symbol: ''
        },
        pickup: {
            blocked: {
                byZipCodes: []
            },
            countries: []
        }
    }
}

function RepairPickUpForm(props: IRepairPickUpForm) {

    const t = useTranslate();
    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const [minDate] = useState(getNextBusinessDate(new Date()));
    const [holidays, setHolidays] = useState<Set<string>>(new Set());
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const language = useSelector<AppStateT, string>((state) => {
        let languageCode = countriesCodes.find((code) => state.i18n.language === code.countryCode);
        // exception for slovak
        if (!languageCode && state.i18n.language === 'sk') languageCode = countriesCodes.find((code) => code.localeCode === 'cs' && code.text === 'Slovak');
        return languageCode?.localeCode || defaultLanguage
    });
    const [state, setState] = useReducer(Store, {
        ...initialValues,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email?.replace(' ', '').toLowerCase(),
        phoneNumber: user.phoneNumber,
        date: minDate,
        location: {
            address: user.location.address,
            city: user.location.city,
            country: user.location.country,
            zipCode: user.location.zipCode,
            countryCode: user.location.countryCode,
            formattedAddress: user.location.formattedAddress,
            coords: user.location.coords
        }
    });

    const { isNotMobile } = useDeviceType();

    useEffect(() => {
        if (user) {
            setState({
                type: 'SET_USER_STATE',
                data: {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email?.replace(' ', '').toLowerCase(),
                    phoneNumber: user.phoneNumber,
                    location: {
                        address: user.location.address,
                        city: user.location.city,
                        country: user.location.country,
                        zipCode: user.location.zipCode,
                        countryCode: user.location.countryCode,
                        formattedAddress: user.location.formattedAddress,
                        coords: user.location.coords
                    }
                }
            });
        }
    }, [user, minDate]);

    const getActualUserData = useCallback(() => {
        const token = localStorage.getItem('token');
        if (!token) return;
        runApi('user/me', {}, (r: any) => {
            if (r.result && r.data) {
                dispatch(setUser(r.data, token));
            }
        }, ApiSendMethods.get);
    }, [dispatch]);

    const setSt = useCallback((dataType: string, data: any) => {
        setState({ type: 'SET_DATA', dataType, data });
    }, []);

    const readConfigPickup = useCallback(() => {
        runApi(`claim-resolution/${props.claimId}/repair/config`, {}, (r: any) => {
            console.log('data: ', r.data);
            if (r.result && r.data.extraService && r.data.extraService.currencyIsoCode) {
                setState({
                    type: 'SET_CONFIG',
                    data: r.data
                });
            }
        }, ApiSendMethods.get);
    }, [props.claimId])

    useEffect(() => {
        readConfigPickup();
        getActualUserData();
    }, [readConfigPickup, getActualUserData]);

    useEffect(() => {
        setState({ type: 'SET_VALID_COUNTRY', data: state.config.pickup.countries.includes(state.location.countryCode.toUpperCase()) });
    }, [state.config.pickup.countries, state.location.countryCode]);

    useEffect(() => {
        setSt('valid', (
            state.name.trim().length >= 3 &&
            (state.location?.formattedAddress?.trim() && state.location.formattedAddress.trim().length) >= 10 &&
            state.phoneNumber.trim().length >= 9 &&
            validField('email', state.email) &&
            state.validCountry &&
            !state.config.pickup.blocked.byZipCodes.includes(state.location.zipCode) &&
            !!state.date
        ));
    }, [setSt, state.name, state.date, state.email, state.location.formattedAddress, state.phoneNumber, state.config.pickup, state.location.countryCode, state.location.zipCode, state.validCountry]);

    const actionChangeLocation = (data: any, location: any, address: AddressT) => {
        setState({
            type: 'SET_ADDRESS',
            fullAdress: data,
            location: {
                address: address.address,
                city: address.city,
                country: address.country,
                zipCode: address.zipCode,
                formattedAddress: data,
                countryCode: address.countryCode,
                coords: location,
            }
        });
    }

    const actionSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!state.valid || props.loading || state.sending || !!props.isBlocked) return;

        let dataSend: any = {
            apt: state.floor,
            courierNotes: state.description,
            time: getMiddleOfTheDay(state.date).toISOString(),
            date: formatDateSaleforce(state.date),
            leaveAt: state.courierNotes,
            name: state.name,
            location: state.location,
            email: state.email?.replace(' ', '').toLowerCase(),
            phoneNumber: state.phoneNumber
        }
        if (state.costExtraChecked) {
            dataSend = {
                ...dataSend, extraService: {
                    service: 'NO_DISPOSAL',
                    payment: { selectedType: PaymentsE.CASH_ON_DELIVERY }
                }
            }
        }

        setSt('sending', true);
        runApi('claim-resolution/' + props.claimId + '/repair/pick-up', dataSend, (r: ResultApiTypeT) => {
            setSt('sending', false);
            if (r.result) {
                props.close();
                dispatch(reload());
            } else {
                runNotify({ message: r.error.message || t('common.formSendError'), type: 'error' });
            }
        });

    }

    const actionCheckExtraService = (checked: boolean) => {
        setSt('costExtraChecked', checked);
    }

    const actionShowCalendar = () => {
        setIsOpen(!isOpen);
    };

    function onFocus(evt: React.FocusEvent<HTMLInputElement>) {
        evt.preventDefault();
        setIsOpen(true);
    }

    const onChangeCalendar = (date: Date | null) => {
        setSt('date', date);
        setIsOpen(false);
    };

    useEffect(() => {
        const hd = new Holidays();
        const supportedCountries = hd.getCountries();

        if (state.location.countryCode) {
            const country = state.location.countryCode.toUpperCase();

            if (supportedCountries[country]) {
                hd.init(country);
                const currentYear = new Date().getFullYear();
                const years = [currentYear - 1, currentYear, currentYear + 1];

                const holidayDates = years.flatMap(year => {
                    return hd.getHolidays(year)?.map(holiday => {
                        const { date } = holiday;
                        return new Date(date).toISOString().split('T')[0];
                    }) || []
                })

                setHolidays(new Set(holidayDates));
            } else {
                setHolidays(new Set());
            }
        }
    }, [state.location.countryCode]);

    const disableHolidaysAndWeekends = useMemo(() => {
        return ({ date }: { date: Date }) => {
            const formattedDate = date.toISOString().split('T')[0];
            return isWeekend(date) || holidays.has(formattedDate);
        };
    }, [holidays]);

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 60);

    return (
        <form onSubmit={actionSubmit} className={cx(styles.body, { [styles.mobile]: !isNotMobile })}>
            <div className={cx(styles.main, { [styles.loading]: state.sending })}>

                {state.sending &&
                    <div className={styles.loader}>
                        <Loader />
                    </div>
                }

                <div
                    className={cx({ [styles.form]: isNotMobile })}
                >
                    <ChatMessageGroup
                        as="MessageGroup"
                        direction="incoming"
                        avatar="bot"
                        data={[
                            {
                                message: t('repair.pickUpModalMessage'),
                                className: chatStyles.messageIn,
                            },
                        ]}
                    />

                    <Input
                        label={t('labels.recipientName')}
                        value={state.name}
                        onChange={data => setSt('name', formatInput(data.target.value))}
                        className={styles.input}
                        notValid={state.name.trim().length < 3}
                        id={'input_pickup_name'}
                    />

                    <Input
                        label={t('createClaim.sumEmail')}
                        value={state.email}
                        onChange={data => setSt('email', data.target.value?.replace(' ', '').toLowerCase())}
                        className={styles.input}
                        notValid={!validField('email', state.email)}
                        id={'input_pickup_email'}

                    />

                    <Input
                        label={t('createClaim.sumPhone')}
                        value={state.phoneNumber}
                        onChange={data => setSt('phoneNumber', data.target.value)}
                        className={styles.input}
                        notValid={state.phoneNumber.trim().length < 9}
                        id={'input_pickup_phone'}
                    />

                    <LocationSearch
                        valueAsText
                        valueOnly
                        label={t('labels.address')}
                        value={state.location.formattedAddress}
                        onChange={actionChangeLocation}
                        geocodeLocation={false}
                    >
                        {(_, onClick) => (
                            <Input
                                value={state.location.formattedAddress}
                                label={t('labels.address')}
                                onClick={onClick}
                                readOnly
                                touched
                                className={styles.input}
                                notValid={!((state.location?.formattedAddress?.trim() && state.location.formattedAddress.trim().length) >= 10)}
                                id={'input_pickup_location'}
                            // error={props.errors.location ? FORM_VALIDATION.FULL_ADDRESS : null}
                            />
                        )}
                    </LocationSearch>

                    <div className={cx(styles.input, styles.calendarLayer)} onClick={actionShowCalendar}>
                        <Input
                            label={t('labels.pickUpDate')}
                            value={state.date ? formatDatePickerDate(state.date, language) : ''}
                            className={styles.inputDate}
                            onFocus={onFocus}
                            readOnly
                            id={'input_pickup_date'}
                        />
                        <CalendarIcon className={styles.calendar} />
                    </div>

                    {isOpen && (
                        <MessageModal open={isOpen} onClose={() => setIsOpen(false)} centered>
                            <Calendar
                                value={state.date}
                                onChange={onChangeCalendar}
                                locale={language || 'en'}
                                minDate={minDate}
                                maxDate={maxDate}
                                tileDisabled={disableHolidaysAndWeekends}
                            />
                        </MessageModal>
                    )}

                    {/* {(state.costExtra.amount > 0 && state.costExtra.amount !== null) && */}
                    {(state.config.extraService.cost > 0 && state.config.extraService.cost !== null) &&
                        <div className={styles.extraService} onClick={() => actionCheckExtraService(!state.costExtraChecked)}>
                            <Checkbox
                                checked={state.costExtraChecked}
                                name='check extra'
                                id={'checkbox_pickup_extra'}
                            // onChange={data => actionCheckExtraService(data)}
                            />
                            <div className={styles.extraServiceContent}>
                                {state.config.extraService.cost > 0 ?
                                    <>
                                        {parse(t('repair.extraServiceConternt', { amount: `<strong>${state.config.extraService.cost}</strong>`, currency: `<strong>${state.config.extraService.currencyIsoCode}</strong>` }))}
                                    </>:
                                    <>
                                        {parse(t('repair.extraServiceContentFree'))}
                                    </>
                                }
                            </div>
                        </div>
                    }

                    {/* <span className={styles.noteTitle}>{t('pickUpForm.courierNotes')}</span>

                    <div className={cx(styles.dropRow, { [styles.dropRowDesktop]: isNotMobile })}>
                        <button className={cx({ [styles.active]: state.courierNotes === 'door' })} onClick={() => setSt('courierNotes', 'door')} type="button">
                            {t('pickUpForm.leaveAtDoor')}
                        </button>
                        <button className={cx({ [styles.active]: state.courierNotes === 'reception' })} onClick={() => setSt('courierNotes', 'reception')} type="button">
                            {t('pickUpForm.atTheReception')}
                        </button>
                        <button className={cx({ [styles.active]: state.courierNotes === 'porch' })} onClick={() => setSt('courierNotes', 'porch')} type="button">
                            {t('pickUpForm.backPorch')}
                        </button>
                    </div> */}

                    {/* <Input
                        label={t('pickUpForm.courierNotesPlaceholder')}
                        value={state.description}
                        onChange={data => setSt('description', data.target.value)}
                        className={cx(styles.input, styles.textarea)}
                        InputComponent={'textarea' as any}
                    /> */}
                </div>
            </div>
            <div className={styles.buttons}>

                {(state.config.pickup.countries.length > 0 && !state.validCountry) &&
                    <div className={styles.noCountrySupported}>
                        {t('repair.noCountrySupport')}
                    </div>
                }

                <Button type="submit" className={styles.button} disabled={!state.valid || props.loading || state.sending || state.config.extraService.cost === null} id={'button_pickup_submit'}>
                    {t('repair.pickUpConfirm')}
                </Button>
            </div>
        </form>
    );
}

export default RepairPickUpForm;
